import {
    NgZone, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Injector,
    Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren,
    ChangeDetectorRef, TrackByFunction
} from '@angular/core';
import { PullPlanTaskTrackedModel } from '@shared/PullPlanTaskTrackedModel';
import {
    ActivityDto, ActivityLinkDto, ActivityTypeEnum, BuildLocationDto, PullPlanActivityLinkDto, PullPlanTaskDto,
    PullPlanTaskPredecessorDto, PullPlanTeamDto, SwimlaneDto, WhiteboardDto
} from '@shared/service-proxies/service-proxies';
import { fromEvent, Subscription, throttleTime } from 'rxjs';
import { ITaskDragEvent, ITaskMouseEvent, ITaskSelectData, TaskDragData, TaskDragEvent, TaskNoteComponent } from '../tasknote/tasknote.component';
import { CustomComponentBase } from 'shared/common/custom-component-base';
import { DateTime } from 'luxon';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { PullplanTaskLinkComponent } from '../pullplan-task-link/pullplan-task-link.component';
import { SizeAndPositionModel } from '@shared/SizeAndPositionModel';
import { GanttActivityLinkComponent } from '../gantt-activity-link/gantt-activity-link.component';
import { ProjectMasterPlanHelpers } from '@app/main/projectPlanning/projects/project-masterplan.helpers';
import * as luxon from 'luxon';
import { BaselineTaskNoteComponent } from '../baseline-tasknote/baseline-tasknote.component';

//#region enums / classes

export enum DisplayMode {
    SwimlanesByProjectTeam = 0,
    SwimlanesByLocation = 1
}

enum ScrollSyncEnum {
    Grid = 0,
    Row = 1,
    Column = 2,
    GanttRow = 3
}

enum RowItemTypeEnum {
    Swimlane = 0,
    BaselineSwimlane = 1,
    Location = 2,
    BaselineLocation = 3
}

export class RowItem {
    index: number;
    type: RowItemTypeEnum;
    title: string;
    compactTitle: string;
    swimlane: SwimlaneDto;
    multiplier: number;
    selected: boolean;
    deleted: boolean;

    get multiplierClass(): string {
        if (!this.multiplier || this.multiplier <= 0) { return ''; }
        return `row${this.multiplier}x`;
    }

    constructor(index: number, type: RowItemTypeEnum, title: string, compactTitle: string, swimlane: SwimlaneDto, multiplier: number) {
        this.index = index;
        this.type = type;
        this.title = title;
        this.compactTitle = compactTitle;
        this.swimlane = swimlane;
        this.multiplier = multiplier;
        this.selected = false;
    }
}

export class ColumnItem {
    index: number;
    title: string;
    compactTitle: string;
    _date: DateTime;
    selected: boolean;
    get date(): DateTime {
        return this._date;
    }
    set date(value: DateTime) {
        this._date = value;
        if (!this._date) {
            this._isWeekend = false;
        } else {
            this._isWeekend = (this._date.weekday >= 6);
        }
    }
    multiplier: number;
    _isWeekend: boolean;
    get isWeekend(): boolean {
        return this._isWeekend;
    }

    get multiplierClass(): string {
        if (!this.multiplier || this.multiplier <= 0) { return ''; }
        return `col${this.multiplier}x`;
    }

    constructor(index: number, title: string, compactTitle: string, date: DateTime, multiplier: number) {
        this.index = index;
        this.title = title;
        this.compactTitle = compactTitle;
        this.date = date;
        this.multiplier = multiplier;
        this.selected = false;
    }
}

class GridCellItem {
    style: string;
    classes: string;
    row: RowItem;
    column: ColumnItem;

    constructor(style: string, classes: string, row: RowItem, column: ColumnItem) {
        this.style = style;
        this.classes = classes;
        this.row = row;
        this.column = column;
    }
}

export class ActivityDtoWithLayout extends ActivityDto {
    left: string;
    top: string;
    width: string;

    constructor(activity: ActivityDto) {
        super(activity);
    }
}

export class GridCellIdentifier {
    date: DateTime;
    swimlaneId: number | undefined;
    //for future dev, e.g. location
    constructor(date: DateTime, swimlaneId: number) {
        this.date = date;
        this.swimlaneId = swimlaneId;
    }
    public equals(cell: GridCellIdentifier): boolean {
        return this.date.day == cell.date.day
            && this.date.month == cell.date.month
            && this.date.year == cell.date.year
            && this.swimlaneId == cell.swimlaneId;
    }
}

export class WhiteboardScrollPositions {
    ganttVScroll: number = 0;
    vScroll: number = 0;
    hScroll: number = 0;
    constructor(ganttVScroll: number, vScroll: number, hScroll: number) {
        this.ganttVScroll = ganttVScroll;
        this.vScroll = vScroll;
        this.hScroll = hScroll;
    }
}

export class WhiteboardDateRange {
    whiteboardStartDate: DateTime;
    whiteboardEndDate: DateTime;
    constructor(startDate: DateTime, endDate: DateTime) {
        this.whiteboardStartDate = startDate;
        this.whiteboardEndDate = endDate;
    }
}

class SwimlaneIndexTracking {
    swimlaneId: number;
    oldIndex: number;
    newIndex: number;
    constructor(swimlaneId: number, oldIndex: number, newIndex: number) {
        this.swimlaneId = swimlaneId;
        this.oldIndex = oldIndex;
        this.newIndex = newIndex;
    }
}

class WeekHeader {
    weekNumber: number = 0;
    cssclass: string = '';
    constructor(weekNumber: number, cssclass: string) {
        this.weekNumber = weekNumber;
        this.cssclass = cssclass;
    }
}

export class InsertSwimlaneData {
    row: RowItem;
    displayMode: DisplayMode;
    constructor(row: RowItem, displayMode: DisplayMode) {
        this.row = row;
        this.displayMode = displayMode;
    }
}
//#endregion

@Component({
    selector: 'pullplan-whiteboard',
    templateUrl: './pullplan-whiteboard.component.html',
    styleUrls: ['./pullplan-whiteboard.component.scss'],
})
export class PullplanWhiteboardComponent extends CustomComponentBase implements OnInit, OnChanges, AfterViewInit, AfterContentInit, OnDestroy {

    @ViewChild('whiteboardOuterContainer', { static: true }) whiteboardOuterContainerElementRef: ElementRef;
    @ViewChild('colheaders', { static: true }) colHeadersElementRef: ElementRef;
    @ViewChild('rowheaders', { static: true }) rowHeadersElementRef: ElementRef;
    @ViewChild('ganttrowheaders', { static: true }) ganttRowHeadersElementRef: ElementRef;
    @ViewChild('ganttcontents', { static: true }) ganttContentsElementRef: ElementRef;
    @ViewChild('gridcontents', { static: true }) gridContentsElementRef: ElementRef;
    @ViewChildren('activityLink') activityLinkComponents: QueryList<GanttActivityLinkComponent>;
    @ViewChildren('pullPlanTask') pullPlanTaskComponents: QueryList<TaskNoteComponent>;
    @ViewChildren('taskLink') taskLinkComponents: QueryList<PullplanTaskLinkComponent>;
    @ViewChild('taskDragLink', { static: true }) taskDragLinkComponent: PullplanTaskLinkComponent;
    @ViewChild('columnMenu') columnMenu: Menu;
    @ViewChild('rowMenu') rowMenu: Menu;
    @ViewChild('linkDragImage', { static: true }) linkDragImage: ElementRef;
    @ViewChildren('baselineTask') baselineTaskComponents: QueryList<BaselineTaskNoteComponent>;

    //simple properties for binding
    @Input() displayMode: DisplayMode = DisplayMode.SwimlanesByProjectTeam;
    @Input() weekNumberStartDate: DateTime;
    @Input() readonly: boolean = false; // ok
    @Input() showLinks: boolean = true; // ok
    @Input() ganttRowVisible: boolean = false; // ok
    @Input() showBaselineContent: boolean = false; // ok
    @Input() multiSelectModeEnabled: boolean = false;
    @Input() showSnapshotComparisonIcons: boolean = false;
    @Input() showWeekNumbers: boolean = true; // ok

    //these are no longer @Input() properties for binding - see replacement methods
    scale: number = 1; //replaced with ChangeScale()

    //these are no longer @Input() properties for binding - replaced with the loadData() method
    whiteboardId: number = 0;
    swimlanes: SwimlaneDto[] = [];
    activityLinks: PullPlanActivityLinkDto[]; //includes the Activities
    tasks: PullPlanTaskTrackedModel[] = [];
    unallocatedTasks: PullPlanTaskDto[] = [];
    predecessorLinks: PullPlanTaskPredecessorDto[] = [];
    baselineSwimlanes: SwimlaneDto[] = [];
    baselineTasks: PullPlanTaskDto[] = [];
    whiteboardStartDate: DateTime;
    whiteboardEndDate: DateTime;

    @Output() TaskDragStart = new EventEmitter<ITaskDragEvent>(); //for when the user starts dragging a Whiteboard Task
    @Output() TaskAdded = new EventEmitter<PullPlanTaskDto>(); //for when the user drags a Task onto the Whiteboard; Task returned
    @Output() TaskMoved = new EventEmitter<PullPlanTaskDto>(); //for when the user moves a Task on the Whiteboard; Task returned
    @Output() TaskRemoved = new EventEmitter<PullPlanTaskDto>(); //for when the user drags a Task off of the Whiteboard; Task returned
    @Output() TaskLinkCreated = new EventEmitter<PullPlanTaskPredecessorDto>(); //for when the user adds a Task Link on the Whiteboard; TaskPredecessor returned
    @Output() TaskDeleted = new EventEmitter<number>(); //for when the user Deletes a Task
    @Output() AddNewTask = new EventEmitter<GridCellIdentifier>(); //for when the user clicks a cell to add a new Task
    @Output() EditTask = new EventEmitter<number>();
    @Output() DuplicateTask = new EventEmitter<number>();
    @Output() CompleteTask = new EventEmitter<number>();

    @Output() SwimlaneEdited = new EventEmitter<SwimlaneDto>(); //for when a swimlane is added/edited
    @Output() SwimlaneMoved = new EventEmitter<SwimlaneDto>(); //for when a swimlane is moved
    @Output() SwimlaneRemoved = new EventEmitter<SwimlaneDto>(); //for when a swimlane is removed
    @Output() SwimlaneInsertedAbove = new EventEmitter<SwimlaneDto>(); //for when a swimlane is inserted above
    @Output() SwimlaneInsertedBelow = new EventEmitter<SwimlaneDto>(); //for when a swimlane is inserted below
    @Output() InsertSwimlaneAbove = new EventEmitter<InsertSwimlaneData>(); //for when a user selects "Insert Swimlane Above"
    @Output() InsertSwimlaneBelow = new EventEmitter<InsertSwimlaneData>(); //for when a user selects "Insert Swimlane Below"

    @Output() DateColumnAdded = new EventEmitter<WhiteboardDateRange>(); //for when a Date column is added
    @Output() DateColumnRemoved = new EventEmitter<WhiteboardDateRange>(); //for when a Date column is removed

    @Output() CreateTasksForActivity = new EventEmitter<number>(); //for when a user selected "Create Tasks for Activity" from a gantt row dropdown
    @Output() GanttRowToggled = new EventEmitter<boolean>(); //for when the gantt row is toggled (shown/hidden)
    @Output() ScrollPositionsChanged = new EventEmitter<WhiteboardScrollPositions>(); //for when any of the scroll bars are moved (this is throttled)

DEBUG = false;

    dataLoaded = false;
    cellSize: number = 220; //pixels
    taskSize: number = 200; //pixels
    taskOffset: number = 10; //pixels
    columnHeadersHeight: number = 75; //pixels
    ganttActivityHeight: number = 34; //pixels
    ganttMilestoneWidth: number = 28; //pixels
    baselineRowHeight: number = 30; //pixels

    rows: RowItem[] = [];
    columns: ColumnItem[] = [];
    gridCells: GridCellItem[] = [];
    weekHeaders: WeekHeader[] = [];

    newSwimlaneDefaultLabel: string = '';
    activeScroll: string = '';
    nextNewSwimlaneId: number = -1;
    columnMenuItems: MenuItem[];
    rowMenuItems: MenuItem[];
    gridDragOver$: Subscription = Subscription.EMPTY;

    activities: ActivityDtoWithLayout[];
    private GridHoverClass: string = 'drag-hover';
    private ganttPixelsPerMinute: number;
    ganttContentsMinHeightStyle: string = '';
    ActivityTypeEnum: typeof ActivityTypeEnum = ActivityTypeEnum;
    suspendResyncScrollPosition: boolean = false;

    longDayNames: string[] = [];
    shortDayNames: string[] = [];
    ganttGridWidth: number = 1000;

    get selectedTasks(): PullPlanTaskTrackedModel[] {
        return this.tasks?.filter(t => t.selected);
    }

    constructor(
        injector: Injector,
        private zone: NgZone,
        private cdRef: ChangeDetectorRef
    ) {
        super(injector);
    }

    //#region ng handlers
    ngOnInit(): void {
        //build arrays of day names to save cpu time later in buildColumns()
        this.longDayNames = [];
        this.shortDayNames = [];
        for (let i = 0; i < 7; i++) {
            this.longDayNames.push(luxon.Info.weekdays('long')[i]);
            this.shortDayNames.push(luxon.Info.weekdays('short')[i]);
        }

        this.newSwimlaneDefaultLabel = this.l('WhiteboardNewSwimlane');
        if (!this.isNullOrUndefined(this.activityLinks)) {
            this.splitActivitiesFromPullPlan('ngOnInit()');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes || !this.dataLoaded) { return; }

        //let swimlanesChanged = !this.isNullOrUndefined(changes.swimlanes);
        //let baselineSwimlanesChanged = !this.isNullOrUndefined(changes.baselineSwimlanes);
        //let baselineTasksChanged = !this.isNullOrUndefined(changes.baselineTasks);
        let showBaselineContentChanged = !this.isNullOrUndefined(changes.showBaselineContent);
        //let tasksChanged = !this.isNullOrUndefined(changes.tasks);
        //let activitiesChanged = !this.isNullOrUndefined(changes.activityLinks);
        let scaleChanged = !this.isNullOrUndefined(changes.scale);
        let weekNumbersChanged = !this.isNullOrUndefined(changes.showWeekNumbers || changes.weekNumberStartDate);
        let displayModeChanged = !this.isNullOrUndefined(changes.displayMode);

        if (this.DEBUG) {
            console.log(`ngOnChanges(
                showBaselineContentChanged: ${showBaselineContentChanged},
                scaleChanged: ${scaleChanged}),
                weekNumbersChanged: ${weekNumbersChanged}),
                changes:`, changes);
        }

        /*
                swimlanesChanged: ${swimlanesChanged}, 
                baselineSwimlanesChanged: ${baselineSwimlanesChanged},
                activitiesChanged: ${activitiesChanged},
                tasksChanged: ${tasksChanged},
                baselineTasksChanged: ${baselineTasksChanged},
        */

        if (displayModeChanged) {
            this.buildGrid('ngOnChanges()');
            this.positionTasks('ngOnChanges()');
            this.positionTaskLinks('ngOnChanges()');

            if (this.showBaselineContent) {
                this.positionBaselineTasks('ngOnChanges()');
            }
        }

        //if the swimlanes or baseline swimlanes have changed, redraw the grid
        //if (swimlanesChanged || baselineSwimlanesChanged || showBaselineContentChanged) {
        if (showBaselineContentChanged) {
            ////...so long as tasks haven't changed as well, as this will trigger a redraw anyway
            // if (!tasksChanged) {
            this.buildGrid('ngOnChanges()');
            this.positionTasks('ngOnChanges()');
            this.positionTaskLinks('ngOnChanges()');
            this.positionBaselineTasks('ngOnChanges()');
            //}
        }

        if (weekNumbersChanged) {
            this.buildGrid('ngOnChanges()');
        }

        // if (tasksChanged) {
        //     this.updateAfterTaskChanges('ngOnChanges()');
        // }

        // if (activitiesChanged) {
        //     this.splitActivitiesFromPullPlan('ngOnChanges()');
        //     this.positionGanttActivities('ngOnChanges()');
        //     this.positionGanttActivityLinks('ngOnChanges()');
        // }

        if (scaleChanged) {
            this.setScale('ngOnChanges()');
        }

        // if (!this.isNullOrUndefined(changes.showSnapshotComparisonIcons)) {
        // }
    }

    ngAfterViewInit() {
        let context = this;

        this.gridDragOver$ = fromEvent(this.gridContentsElementRef.nativeElement, 'dragover')
            .pipe(throttleTime(30))
            .subscribe((ev: DragEvent) => {
                this.zone.runOutsideAngular(() => {
                    context.onGridDragOver(ev);
                });
            });

        this.pullPlanTaskComponents.changes.subscribe((items) => {
            if (context.isNullOrUndefined(items) || items.length == 0) { return; }
            context.positionTasks('ngAfterViewInit(pullPlanTaskComponents.changes.subscribe)');
        });

        this.taskLinkComponents.changes.subscribe((items) => {
            if (context.isNullOrUndefined(items) || items.length == 0) { return; }
            context.positionTaskLinks('ngAfterViewInit(taskLinkComponents.changes.subscribe)');
        })

        this.activityLinkComponents.changes.subscribe((items) => {
            if (context.isNullOrUndefined(items) || items.length == 0) { return; }
            if (this.ganttRowVisible) {
                context.positionGanttActivityLinks('ngAfterViewInit(activityLinkComponents.changes.subscribe)');
            }
        });

        this.baselineTaskComponents.changes.subscribe((items) => {
            if (context.isNullOrUndefined(items) || items.length == 0) { return; }
            if (this.showBaselineContent) {
                context.positionBaselineTasks('ngAfterViewInit(baselineTaskComponents.changes.subscribe)');
            }
        });
    }

    ngAfterContentInit() {
        //sync the col & row headers scroll scrolling with the grid
        let colHeaders = (this.colHeadersElementRef.nativeElement as Element);
        let rowHeaders = (this.rowHeadersElementRef.nativeElement as Element);
        let grid = (this.gridContentsElementRef.nativeElement as Element);
        let ganttHeaders = (this.ganttRowHeadersElementRef.nativeElement as Element);
        let ganttRow = (this.ganttContentsElementRef.nativeElement as Element);
        let context = this;

        //since we don't affect any values by scrolling, we can achieve
        //super performance here by running these outside of the main Zone
        this.zone.runOutsideAngular(() => {
            //sometimes scrolling can get out of sync, so periodically resync the panels
            setInterval(() => {
                if (this.suspendResyncScrollPosition) { return; }
                this.resyncScrollPosition(ScrollSyncEnum.Grid)
            }, 150);

            fromEvent(colHeaders, 'mouseenter').subscribe((e: Event) => {
                context.activeScroll = 'colHeaders';
            });
            fromEvent(rowHeaders, 'mouseenter').subscribe((e: Event) => {
                context.activeScroll = 'rowHeaders';
            });
            fromEvent(grid, 'mouseenter').subscribe((e: Event) => {
                context.activeScroll = 'grid';
            });
            fromEvent(ganttHeaders, 'mouseenter').subscribe((e: Event) => {
                context.activeScroll = 'ganttHeaders';
            });
            fromEvent(ganttRow, 'mouseenter').subscribe((e: Event) => {
                context.activeScroll = 'ganttRow';
            });

            fromEvent(colHeaders, 'mouseleave').subscribe((e: Event) => {
                if (context.activeScroll = 'colHeaders') { context.activeScroll = ''; }
            });
            fromEvent(rowHeaders, 'mouseleave').subscribe((e: Event) => {
                if (context.activeScroll = 'rowHeaders') { context.activeScroll = ''; }
            });
            fromEvent(grid, 'mouseleave').subscribe((e: Event) => {
                if (context.activeScroll = 'grid') { context.activeScroll = ''; }
            });
            fromEvent(ganttHeaders, 'mouseleave').subscribe((e: Event) => {
                if (context.activeScroll = 'ganttHeaders') { context.activeScroll = ''; }
            });
            fromEvent(ganttRow, 'mouseleave').subscribe((e: Event) => {
                if (context.activeScroll = 'ganttRow') { context.activeScroll = ''; }
            });

            //scrolling trackers
            fromEvent(colHeaders, 'scroll').pipe(throttleTime(5)).subscribe((e: Event) => {
                if (context.activeScroll == 'colHeaders') {
                    this.suspendResyncScrollPosition = true;
                    grid.scrollLeft = colHeaders.scrollLeft;
                    if (colHeaders.scrollLeft > grid.scrollWidth) {
                        colHeaders.scrollLeft = grid.scrollWidth;
                    }
                    ganttRow.scrollLeft = colHeaders.scrollLeft;
                    this.suspendResyncScrollPosition = false;
                    this.scrollPositionsChanged();
                }
            });
            fromEvent(rowHeaders, 'scroll').pipe(throttleTime(5)).subscribe((e: Event) => {
                if (context.activeScroll == 'rowHeaders') {
                    this.suspendResyncScrollPosition = true;
                    grid.scrollTop = rowHeaders.scrollTop;
                    if (rowHeaders.scrollTop > grid.scrollHeight) {
                        rowHeaders.scrollTop = grid.scrollHeight;
                    }
                    this.suspendResyncScrollPosition = false;
                    this.scrollPositionsChanged();
                }
            });
            fromEvent(grid, 'scroll').pipe(throttleTime(5)).subscribe((e: Event) => {
                if (context.activeScroll == 'grid') {
                    this.suspendResyncScrollPosition = true;
                    colHeaders.scrollLeft = grid.scrollLeft;
                    rowHeaders.scrollTop = grid.scrollTop;
                    ganttRow.scrollLeft = grid.scrollLeft;
                    this.suspendResyncScrollPosition = false;
                    this.scrollPositionsChanged();
                }
            });
            fromEvent(ganttHeaders, 'scroll').pipe(throttleTime(5)).subscribe((e: Event) => {
                if (context.activeScroll == 'ganttHeaders') {
                    this.suspendResyncScrollPosition = true;
                    ganttRow.scrollTop = ganttHeaders.scrollTop;
                    this.suspendResyncScrollPosition = false;
                    this.scrollPositionsChanged();
                }
            });
            fromEvent(ganttRow, 'scroll').pipe(throttleTime(5)).subscribe((e: Event) => {
                if (context.activeScroll == 'ganttRow') {
                    this.suspendResyncScrollPosition = true;
                    ganttHeaders.scrollTop = ganttRow.scrollTop;
                    if (ganttRow.scrollTop > ganttHeaders.scrollHeight) {
                        ganttRow.scrollTop = ganttHeaders.scrollHeight;
                    }
                    grid.scrollLeft = ganttRow.scrollLeft;
                    if (ganttRow.scrollLeft > grid.scrollWidth) {
                        ganttRow.scrollLeft = grid.scrollWidth;
                    }
                    colHeaders.scrollLeft = ganttRow.scrollLeft;
                    this.suspendResyncScrollPosition = false;
                    this.scrollPositionsChanged();
                }
            });
        });
    }

    ngOnDestroy() {
        if (this.gridDragOver$) {
            this.gridDragOver$.unsubscribe();
        }
    }
    //#endregion

    //#region public methods

    public refreshTasks(): void {
        //cause the tasks to be re-rendered (as this is an array, changes to 
        //properties on task objects will not trigger the refresh)
        let newArray = [];
        this.tasks.forEach(item => newArray.push(Object.assign({}, item)));
        this.tasks = [];
        this.tasks = newArray;
    }

    //#endregion

    private splitActivitiesFromPullPlan(source: string): void {
        if (this.DEBUG) { console.log(`splitActivityFromPullPlan(source: ${source})`); }

        if (this.isNullOrUndefined(this.activityLinks)) { return; }

        this.activities = this.activityLinks
            .map(actlink => new ActivityDtoWithLayout(actlink.linkActivityFk))
            .sort((a, b) => ProjectMasterPlanHelpers.sortByOutlineNumber(a.activityOutlineNumber, b.activityOutlineNumber));

        if (this.isNullOrUndefined(this.activities) || this.activities.length == 0) { return; }

        //determine start/finish dates for summaries
        this.activities.forEach(activity => {
            if (activity.activityType == ActivityTypeEnum.Summary) {
                let outline = activity.activityOutlineNumber;
                let children = this.activities
                    .filter(act => act.id != activity.id && act.activityType != ActivityTypeEnum.Summary && act.activityOutlineNumber
                        .indexOf(outline) == 0);
                if (this.arrayHasItems(children)) {
                    let earliestStart = children.sort((a, b) => a.activityStart < b.activityStart ? -1 : a.activityStart > b.activityStart ? 1 : 0)[0].activityStart;
                    let latestFinish = children.sort((a, b) => a.activityStart > b.activityStart ? -1 : a.activityStart < b.activityStart ? 1 : 0)[0].activityFinish;
                    activity.activityStart = earliestStart;
                    activity.activityFinish = latestFinish;
                }
            }
        });
    }

    public refreshTask(taskId: number): void {
        let taskComponent = this.pullPlanTaskComponents.find(t => t.taskId == taskId);
        if (taskComponent != null) {
            taskComponent.refresh();
        }
    }

    private setScale(source: string): void {
        if (this.DEBUG) { console.log(`setScale(source: ${source})`); }

        switch (this.scale) {
            case 2: //1-week
                this.cellSize = 160;
                this.taskSize = 140;
                this.taskOffset = 10;
                break;
            case 3: //2-week
                this.cellSize = 90;
                this.taskSize = 80;
                this.taskOffset = 5;
                break;
            case 4: //4-week (1-month)
                this.cellSize = 90;
                this.taskSize = 80;
                this.taskOffset = 5;
                break;
            case 5: //6-week
                this.cellSize = 90;
                this.taskSize = 80;
                this.taskOffset = 5;
                break;
            case 1: //normal
            default:
                this.cellSize = 220;
                this.taskSize = 200;
                this.taskOffset = 10;
                break;
        }

        this.ganttPixelsPerMinute = this.cellSize / (24 * 60); //minutes per day

        if (!this.dataLoaded) { return; }

        this.buildGrid('setScale()');
        this.positionGanttActivities('setScale()');
        this.positionGanttActivityLinks('setScale()');
        this.positionTasks('setScale()');
        this.positionTaskLinks('setScale()');
        this.positionBaselineTasks('setScale()');
        this.resyncScrollPosition(ScrollSyncEnum.Grid);
    }

    private positionTasks(source: string): void {
        if (this.DEBUG) { console.log(`positionTasks(source: ${source})`); }

        if (this.isNullOrUndefined(this.pullPlanTaskComponents)) { return; }

        let context = this;

        //using Promise.resolve(null) schedules this inline code to run after 
        //the current block has run, which is better than using setTimeout()
        //and avoid the notorious NG0100: ExpressionChangedAfterItHasBeenCheckedError

        this.zone.runOutsideAngular(() => {
            let counter = this.pullPlanTaskComponents.length;
            this.pullPlanTaskComponents.forEach((child: TaskNoteComponent) => {
                Promise.resolve(null).then(() => {
                    let earlier = this.isDateTimeEarlierThan(child.pullPlanTask.taskDate, this.whiteboardStartDate);
                    let later = this.isDateTimeLaterThan(child.pullPlanTask.taskDate, this.whiteboardEndDate);
                    let noswimlane = this.isNullOrUndefined(this.getSwimlaneById(child.pullPlanTask.taskSwimlaneId));
                    let hide = earlier || later || noswimlane;
                    if (!hide) {
                        child.setCustomStyles(context.getTaskStyle(child.pullPlanTask));
                    } else if (this.DEBUG) {
                        console.log(`positionTasks() Task hidden because: earlier:[${earlier}], later:[${later}], noswimlane:[${noswimlane}]`);
                    }
                    child.hide = hide;
                    counter--;
                    if (counter == 0) { this.cdRef.detectChanges(); }
                });
            });
        });
    }

    private positionBaselineTasks(source: string): void {
        if (this.DEBUG) { console.log(`positionBaselineTasks(source: ${source})`); }
        if (!this.showBaselineContent || this.isNullOrUndefined(this.baselineTasks)) { return; }

        let context = this;

        //using Promise.resolve(null) schedules this inline code to run after 
        //the current block has run, which is better than using setTimeout()
        //and avoid the notorious NG0100: ExpressionChangedAfterItHasBeenCheckedError

        this.zone.runOutsideAngular(() => {
            let counter = this.baselineTaskComponents.length;
            this.baselineTaskComponents.forEach((child: BaselineTaskNoteComponent) => {
                Promise.resolve(null).then(() => {
                    let earlier = this.isDateTimeEarlierThan(child.baselineTask.taskDate, this.whiteboardStartDate);
                    let later = this.isDateTimeLaterThan(child.baselineTask.taskDate, this.whiteboardEndDate);
                    let noswimlane = this.isNullOrUndefined(this.getBaselineSwimlaneById(child.baselineTask.taskSwimlaneId));
                    let hide = earlier || later || noswimlane;
                    if (!hide) {
                        child.setCustomStyles(context.getBaselineTaskStyle(child.baselineTask));
                    } else if (this.DEBUG) {
                        console.log(`positionBaselineTasks() Task hidden because: earlier:[${earlier}], later:[${later}], noswimlane:[${noswimlane}]`);
                    }
                    child.hide = hide;
                    counter--;
                    if (counter == 0) { this.cdRef.detectChanges(); }
                });
            });
        });
    }

    private positionTaskLinks(source: string): void {
        if (this.DEBUG) { console.log(`positionTaskLinks(source: ${source})`); }

        if (this.isNullOrUndefined(this.taskLinkComponents)) { return; }

        let context = this;

        //using Promise.resolve(null) schedules this inline code to run after 
        //the current block has run, which is better than using setTimeout()
        //and avoid the notorious NG0100: ExpressionChangedAfterItHasBeenCheckedError

        this.zone.runOutsideAngular(() => {
            let counter = this.taskLinkComponents.length;
            this.taskLinkComponents.forEach((link: PullplanTaskLinkComponent) => {
                let parentTask = context.getTask(link.parentTaskId)
                let childTask = context.getTask(link.taskId);

                Promise.resolve(null).then(() => {
                    if (context.isNullOrUndefined(parentTask) || context.isNullOrUndefinedOrNaN(parentTask.taskSwimlaneId)
                        || context.isNullOrUndefined(childTask) || context.isNullOrUndefinedOrNaN(childTask.taskSwimlaneId)) {
                        //hide the link
                        link.visible = false;
                    } else {
                        link.drawLink(context.getTaskPosition(parentTask), context.getTaskPosition(childTask));
                    }
                    counter--;
                    if (counter == 0) { this.cdRef.detectChanges(); }
                });
            });
        });
    }

    private positionGanttActivities(source: string): void {
        if (this.DEBUG) { console.log(`positionGanttActivities(source: ${source})`); }

        if (this.isNullOrUndefined(this.activities)) { return; }

        let context = this;

        //using Promise.resolve(null) schedules this inline code to run after 
        //the current block has run, which is better than using setTimeout()
        //and avoid the notorious NG0100: ExpressionChangedAfterItHasBeenCheckedError

        this.zone.runOutsideAngular(() => {
            let idx = 0;
            this.activities.forEach((child: ActivityDtoWithLayout) => {
                Promise.resolve(null).then(() => {
                    child.left = context.getActivityLeft(child);
                    child.top = context.getActivityTop(idx);
                    child.width = context.getActivityWidth(child);
                    idx++;
                });
            });
        });
    }

    private positionGanttActivityLinks(source: string): void {
        if (this.DEBUG) { console.log(`positionGanttActivityLinks(source: ${source})`); }

        if (this.isNullOrUndefined(this.activityLinkComponents) || this.activityLinkComponents.length == 0) { return; }

        let positionLinksCounter = this.activityLinkComponents.length;
        let context = this;

        //using Promise.resolve(null) schedules this inline code to run after 
        //the current block has run, which is better than using setTimeout()
        //and avoid the notorious NG0100: ExpressionChangedAfterItHasBeenCheckedError

        this.zone.runOutsideAngular(() => {
            this.activityLinkComponents.forEach((link: GanttActivityLinkComponent) => {

                Promise.resolve(null).then(() => {
                    let parentActivity = context.getActivity(link.parentActivityId)
                    let childActivity = context.getActivity(link.activityId);

                    if (context.isNullOrUndefined(parentActivity) || context.isNullOrUndefined(childActivity)
                        || context.isNullOrUndefinedOrNaN(parentActivity.left) || context.isNullOrUndefinedOrNaN(childActivity.left)) {
                        link.visible = false;
                    } else {
                        let parent = context.getActivityPosition(parentActivity);
                        parent.yOffset = -2;
                        if (parentActivity.activityType == ActivityTypeEnum.Milestone) {
                            parent.left -= 13;
                            parent.width = context.ganttMilestoneWidth;
                        }

                        let child = context.getActivityPosition(childActivity);
                        child.yOffset = -2;
                        if (childActivity.activityType == ActivityTypeEnum.Milestone) {
                            child.left -= 13;
                            child.width = context.ganttMilestoneWidth;
                        }

                        link.drawLink(parent, child);
                        link.visible = true;
                    }

                    positionLinksCounter--;
                    // if (positionLinksCounter == 0 && callback) { callback(); }
                });
            });
        });
    }


    //##################################
    // BEGIN: DRAG'N'DROP EVENT HANDLERS

    //unused, as have moved this to inline javascript on the element
    // onDragOver(ev: DragEvent): void {
    //   if (ev.dataTransfer.effectAllowed != 'move') {
    //     return;
    //   }
    //   ev.preventDefault(); //this is the "magic" that allows for a drop zone to work properly!
    //   ev.dataTransfer.dropEffect = 'move';
    // }

    onDrop(ev: DragEvent): void {
        ev.preventDefault();
    }

    onGridCellDragEnter(ev: DragEvent, source: GridCellItem): void {
        //we only want to add/remove the cell hover class if the item being dragged over is a Task, and exclude baseline row cells
        if (ev.dataTransfer.effectAllowed != 'move' || source.row.type != RowItemTypeEnum.Swimlane) {
            ev.dataTransfer.dropEffect = 'none';
            ev.stopPropagation();
            return;
        }

        let cell = (ev.target as Element);
        this.addCellHoverClass(cell);
    }

    onGridCellDragLeave(ev: DragEvent, source: GridCellItem): void {
        //we only want to add/remove the cell hover class if the item being dragged over is a Task, and exclude baseline row cells
        if (ev.dataTransfer.effectAllowed != 'move' || source.row.type != RowItemTypeEnum.Swimlane) { return; }

        let cell = (ev.target as Element);
        this.removeCellHoverClass(cell);
    }

    onGridCellDrop(ev: DragEvent, source: GridCellItem): void {
        if (source.row.type != RowItemTypeEnum.Swimlane) { return; }

        let cell = (ev.target as Element);
        this.removeCellHoverClass(cell);

        let dragData = this.getDragNDropData(ev.dataTransfer);
        if (this.isNullOrUndefined(dragData) || dragData.type != 'task') { return; } //in case user drags anything else onto the grid!

        //determine the Date and SwimlaneId of the cell the Task has been dropped on to
        //let row = this.getRowByIndex(parseInt(cell.getAttribute('data-row-index'), 10));
        //let column = this.getColumnByIndex(parseInt(cell.getAttribute('data-col-index'), 10));
        let row = this.rows.find(r => r.index === parseInt(cell.getAttribute('data-row-index'), 10));
        let column = this.columns.find(c => c.index === parseInt(cell.getAttribute('data-col-index'), 10));

        let newTaskSwimlaneId = row.swimlane.id;
        //let newTaskDate = column.date.set({ hour: dragData.task.taskDate.hour, minute: dragData.task.taskDate.minute, second: 0, millisecond: 0 }); //ensure the Task time stays the same
        let newTaskDate = DateTime.fromMillis(column.date.valueOf());
        let task = this.getTask(dragData.task.id);
        let swimlane = this.swimlanes.find(s => s.id == newTaskSwimlaneId);

        if (this.isNullOrUndefined(task)) {
            //new task dragged on to the whiteboard
            dragData.task.taskDate = newTaskDate;
            dragData.task.taskSwimlaneId = newTaskSwimlaneId;
            dragData.task.swimlaneTeamTitle = swimlane.swimlaneTeamTitle;
            dragData.task.swimlaneTeamColour = swimlane.swimlaneTeamColour;
            dragData.task.swimlaneTeamTextColour = swimlane.swimlaneTeamTextColour;

            this.TaskAdded.emit(dragData.task);
        } else {
            //existing task dragged to another cell - update the Task with the target Date and Swimlane details
            task.taskDate = newTaskDate;
            task.taskSwimlaneId = newTaskSwimlaneId;
            task.swimlaneTeamTitle = swimlane.swimlaneTeamTitle;
            task.swimlaneTeamColour = swimlane.swimlaneTeamColour;
            task.swimlaneTeamTextColour = swimlane.swimlaneTeamTextColour;

            this.TaskMoved.emit(task.toPullPlanTaskDto());
        }
    }

    onGridCellClick(ev: Event, source: GridCellItem): void {
        if (source.row.type != RowItemTypeEnum.Swimlane) { return; }
        if (this.readonly || this.multiSelectModeEnabled) { return; }
        this.AddNewTask.emit(new GridCellIdentifier(source.column.date, source.row.swimlane.id));
    }

    onTaskDragEvent(ev: TaskDragEvent): void {
        if (ev.event.type != 'dragstart' && ev.event.type != 'dragend' && ev.event.type != 'drop') { return; }

        switch (ev.event.type) {

            case 'dragstart':
                if (this.DEBUG) { console.log(`onTaskDragEvent() event.type: "dragstart"`); }

                //turn off change detection
                this.cdRef.detach();

                //this event is used on the view-whiteboard page to set the drag image
                ev.event.dataTransfer.effectAllowed = 'move';
                this.TaskDragStart.emit({ type: ev.type, task: ev.task, event: ev.event });
                break;

            case 'dragend':
                if (this.DEBUG) { console.log(`onTaskDragEvent() event.type: "dragend"`); }

                //reinstate change detection
                this.cdRef.reattach();
                break;

            case 'drop':
                if (this.DEBUG) { console.log(`onTaskDragEvent() event.type: "drop"`); }

                //reinstate change detection
                this.cdRef.reattach();

                if (ev.event.dataTransfer.effectAllowed == 'link') {
                    //link dragged between existing tasks
                    let linkdata = this.getDragNDropData(ev.event.dataTransfer);

                    //check we're not trying to link a task to itself, or drag a link onto a Task not on the Whiteboard!
                    if (linkdata.task.id == ev.task.id || !ev.task.taskSwimlaneId) { 
                        this.cdRef.markForCheck();
                        return;
                    }

                    let sourceTask = this.getTask(linkdata.task.id);
                    let targetTask = this.getTask(ev.task.id);
                    let newLink = new PullPlanTaskPredecessorDto();
                    newLink.id = 0;

                    if (linkdata.type == 'parent-link') {
                        newLink.taskId = targetTask.id;
                        newLink.taskTitle = targetTask.taskTitle;
                        newLink.predecessorTaskId = sourceTask.id;
                        newLink.predecessorTaskTitle = sourceTask.taskTitle;
                    } else if (linkdata.type == 'child-link') {
                        newLink.taskId = sourceTask.id;
                        newLink.taskTitle = sourceTask.taskTitle;
                        newLink.predecessorTaskId = targetTask.id;
                        newLink.predecessorTaskTitle = targetTask.taskTitle;
                    }

                    this.TaskLinkCreated.emit(newLink);
                } else {
                    //task dropped on to another task
                    let sourceTask = this.getDragNDropData(ev.event.dataTransfer).task;
                    let dropzoneTask = ev.task;

                    if (this.isNullOrUndefined(sourceTask) || this.isNullOrUndefined(dropzoneTask)) {
                        this.cdRef.markForCheck();
                        return;
                    }

                    //check if the user started a drag, then took the task to where it started
                    if (sourceTask.id == dropzoneTask.id) {
                        this.cdRef.markForCheck();
                        return;
                    }

                    //check if this task exists in the whiteboard
                    let task = this.getTask(sourceTask.id);
                    if (this.isNullOrUndefined(task)) {
                        //task dragged from the unallocated list
                        sourceTask.taskDate = dropzoneTask.taskDate;
                        sourceTask.taskSwimlaneId = dropzoneTask.taskSwimlaneId;
                        sourceTask.taskColumnCellIndex = 0;

                        this.TaskAdded.emit(sourceTask);
                    } else {
                        //task dragged from another cell
                        if (task.taskSwimlaneId == dropzoneTask.taskSwimlaneId && this.areDatesEqual(task.taskDate, dropzoneTask.taskDate)) {
                            //pass through the dropzone task id so we can swap their cell column indexes
                            task.swapCellIndexTaskId = dropzoneTask.id;
                        }

                        task.taskDate = dropzoneTask.taskDate;
                        task.taskSwimlaneId = dropzoneTask.taskSwimlaneId;

                        if (this.DEBUG) { console.log('PullPlanWhiteboard Component::onTaskDragEvent(drop) emitting TaskMoved() task:', task); }

                        this.TaskMoved.emit(task.toPullPlanTaskDto());
                    }
                }
                break;
        }

        this.cdRef.markForCheck();
    }

    onTaskLinkDragEvent(ev: TaskDragEvent): void {
        if (ev.event.type != 'dragstart' && ev.event.type != 'dragend') { return; }

        switch (ev.event.type) {

            case 'dragstart':
                let dragData = this.getDragNDropData(ev.event.dataTransfer);
                if (this.isNullOrUndefined(dragData)) { return; } //in case user drags anything else onto the grid!

                ev.event.dataTransfer.effectAllowed = 'link';

                //set the drag image to none (this has to be a hack be using an element with opacity set to zero!)
                ev.event.dataTransfer.setDragImage(this.linkDragImage.nativeElement, -20, -20);

                let taskPosition = this.getTaskPosition(dragData.task);
                let mousePosition = this.getGridMousePosition(ev.event);

                if (dragData.type == 'parent-link') {
                    this.taskDragLinkComponent.parentTaskId = dragData.task.id;
                    this.taskDragLinkComponent.taskId = 0;
                    this.taskDragLinkComponent.drawLink(taskPosition, mousePosition);

                } else if (dragData.type == 'child-link') {
                    this.taskDragLinkComponent.parentTaskId = 0;
                    this.taskDragLinkComponent.taskId = dragData.task.id;
                    this.taskDragLinkComponent.drawLink(mousePosition, taskPosition);

                } else {
                    return; //not a link being dragged!

                }

                break;

            case 'dragend':
                this.taskDragLinkComponent.visible = false;
                break;
        }
    }

    onGridDragOver(ev: DragEvent): void {
        //this event is now throttled using an Observable, otherwise it can generate up to 1000 calls a second!

        //we can filter out dragover events by checking for effectAllowed = 'link' AND that the taskDragLink is visible
        if (ev.dataTransfer.effectAllowed != 'link') { return; } // || !this.taskDragLink.visible) { return; }

        let mousePosition = this.getGridMousePosition(ev);

        if (this.taskDragLinkComponent.taskId == 0) {
            this.taskDragLinkComponent.moveChildPosition(mousePosition);
        } else if (this.taskDragLinkComponent.parentTaskId == 0) {
            this.taskDragLinkComponent.moveParentPosition(mousePosition);
        }
    }

    // END: DRAG'N'DROP EVENT HANDLERS
    //##################################

    onActivityMouseEvent(ev: Event, activity: ActivityDtoWithLayout): void {
        //find associated links and de/highlight
        let links = this.activityLinkComponents.filter(l => l.activityId == activity.id || l.parentActivityId == activity.id);
        if (this.isNullOrUndefined(links) || links.length == 0) { return; }

        switch (ev.type) {
            case 'mouseenter':
                links.forEach(l => l.bringToForeground = true);
                break;

            case 'mouseleave':
                links.forEach(l => l.bringToForeground = false);
                break;
        }
    }

    onTaskMouseEvent(ev: ITaskMouseEvent, fromBaselineTask: boolean): void {
        //find associated links and de/highlight
        let links = this.taskLinkComponents.filter(l => l.taskId == ev.task.id || l.parentTaskId == ev.task.id);

        switch (ev.type) {
            case 'mouseenter':
                if (this.arrayHasItems(links)) {
                    links.forEach(l => l.bringToForeground = true);
                }
                if (fromBaselineTask) {
                    this.highlightTask(ev.task.id, true);
                }
                if (this.showBaselineContent) {
                    this.highlightBaselineTask(ev.task.id, true);
                }
                break;

            case 'mouseleave':
                if (this.arrayHasItems(links)) {
                    links.forEach(l => l.bringToForeground = false);
                }
                if (fromBaselineTask) {
                    this.highlightTask(ev.task.id, false);
                }
                if (this.showBaselineContent) {
                    this.highlightBaselineTask(ev.task.id, false);
                }
                break;
        }
    }

    private highlightTask(taskId: number, highlight: boolean): void {
        let task = this.pullPlanTaskComponents.find(t => t.pullPlanTask.id == taskId);
        if (!this.isNullOrUndefined(task)) {
            task.hover = highlight;
        }
    }

    private highlightBaselineTask(taskId: number, highlight: boolean): void {
        let baselineTask = this.baselineTaskComponents.find(t => t.baselineTask.id == taskId);
        if (!this.isNullOrUndefined(baselineTask)) {
            baselineTask.highlight = highlight;
        }
    }

    //#region GRID LAYOUT FUNCTIONS
    public buildGrid(source: string): void {
        if (this.DEBUG) { console.log(`buildGrid(source: ${source})`); }

        var startTime: number;
        if (this.DEBUG) { startTime = performance.now(); }

        this.buildColumns();
        this.buildRows();
        this.buildGridCells();

        if (this.DEBUG) {
            var endTime = performance.now();
            console.log(`buildGrid(source: ${source}) took ${endTime - startTime}ms`);
        }
    }

    private buildColumns(): void {
        if (this.DEBUG) { console.log('buildColumns()'); }

        var startTime: number;
        if (this.DEBUG) { startTime = performance.now(); }

        let newCols: ColumnItem[] = [];
        let newWeekHeaders: WeekHeader[] = [];

        let start = this.whiteboardStartDate;
        let end = this.whiteboardEndDate;

        if (this.isNullOrUndefined(start) && !this.isNullOrUndefined(end)) { start = end; }
        if (this.isNullOrUndefined(end) && !this.isNullOrUndefined(start)) { end = start; }

        if (this.isNullOrUndefined(start) && this.isNullOrUndefined(end)) {
            start = this.today; // DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            end = start.plus({ days: 7 });
        }

        let overallWidth = 0;
        let days = end.diff(start, 'days').days + 1;
        let lastWeekNumber: number = null;
        let weekCellWidth = 0;

        for (let i = 0; i < days; i++) {
            let dt = start.plus({ days: i });
            let c = this.getColumnCellMultiplier(dt);

            let weekNumber = dt.weekNumber;
            if (!this.isNullOrUndefined(this.weekNumberStartDate)) {
                let weeks = dt.diff(this.weekNumberStartDate, 'weeks').weeks; //decimal

                if (weeks >= 0) {
                    weekNumber = Math.floor(weeks) + 1;
                } else {
                    weekNumber = Math.floor(weeks); //can go negative, which is ok, but no week zero!
                }
            }

            if (this.isNullOrUndefined(lastWeekNumber)) { lastWeekNumber = weekNumber; }

            if (weekNumber != lastWeekNumber) {
                //the current date is in a new week, so add the previous week to the array
                newWeekHeaders.push(new WeekHeader(lastWeekNumber, `week-header-${weekCellWidth}`));
                lastWeekNumber = weekNumber;
                weekCellWidth = c;
            } else {
                weekCellWidth += c;
            }

            if (this.showBaselineContent) {
                let cb = this.getBaselineColumnCellMultiplier(dt);
                if (cb > c) { c = cb; } //use the larger of the two cell multipliers (baseline or main)
            }

            let m = this.calcCellWidthMultiplier(c);

            overallWidth += (m * this.cellSize);
            //toFormat() takes a lot of time to execute!
            // newCols.push(new ColumnItem(newCols.length,
            //     `${dt.toFormat('EEEE')}<br />${dt.toFormat('DD')}`,
            //     `${dt.toFormat('EEE')}<br />${dt.toFormat('d MMM kk')}`, dt, m));

            if (this.arrayHasItems(this.longDayNames) && this.arrayHasItems(this.shortDayNames)) {
                newCols.push(new ColumnItem(newCols.length,
                    `${this.longDayNames[dt.weekday - 1]}<br />${dt.toFormat('DD')}`,
                    `${this.shortDayNames[dt.weekday - 1]}<br />${dt.toFormat('d MMM kk')}`, dt, m));
            } else {
                newCols.push(new ColumnItem(newCols.length, '', '', dt, m));
            }
        }

        newWeekHeaders.push(new WeekHeader(lastWeekNumber, `week-header-${weekCellWidth}`));

        this.ganttGridWidth = overallWidth;
        this.columns = newCols;
        this.weekHeaders = newWeekHeaders;

        if (this.DEBUG) {
            var endTime = performance.now();
            console.log(`buildColumns() took ${endTime - startTime}ms`);
        }
    }

    private buildRows(): void {
        if (this.DEBUG) { console.log('buildRows()'); }

        var startTime: number;
        if (this.DEBUG) { startTime = performance.now(); }


        if (this.isNullOrUndefined(this.swimlanes)) {
            this.rows = [];
            return;
        }

        let newRows: RowItem[] = [];

        switch (this.displayMode) {
            case DisplayMode.SwimlanesByProjectTeam:
                if (!this.arrayHasItems(this.swimlanes)) {
                    //ensure at least one swimlane exists on the whiteboard (Default Swimlane)
                    let title = this.newSwimlaneDefaultLabel;
        
                    newRows.push(new RowItem(0, RowItemTypeEnum.Swimlane, title, title, null, 1));
        
                    let dto = new SwimlaneDto();
                    dto.id = this.nextNewSwimlaneId--;
                    dto.swimlaneIndex = 0;
                    dto.swimlaneTitle = title;
                    dto.swimlaneWhiteboardId = this.whiteboardId;
        
                    this.SwimlaneEdited.emit(dto);
                } else {
                    //sort the swimlanes by index
                    this.swimlanes = this.swimlanes
                        .filter(s => s.swimlaneType == this.displayMode)
                        .sort((a, b) => a.swimlaneIndex - b.swimlaneIndex); //ascending
                        
                    for (let i = 0; i < this.swimlanes.length; i++) {
                        let swimlane = this.swimlanes[i];
                        let c = this.getRowCellMultiplier(swimlane.id, false);
                        let m = this.calcCellHeightMultiplier(c);
                        let title = swimlane.swimlaneTeamTitle ?? swimlane.swimlaneTitle;
        
                        //newRows.push(new RowItem(newRows.length, RowItemTypeEnum.Swimlane, title, title, swimlane, m));
                        newRows.push(new RowItem(swimlane.swimlaneIndex, RowItemTypeEnum.Swimlane, title, title, swimlane, m));
                    }
                }
        
                if (this.showBaselineContent && this.arrayHasItems(this.baselineSwimlanes)) {
                    //interleave the baseline swimlanes with the main swimlanes
                    this.baselineSwimlanes.forEach((swimlane) => {
                        let companionRow = newRows.find(row => row.swimlane.id == swimlane.id);
                        if (companionRow == null) {
                            //this swimlane has since been deleted from the whiteboard, so move it to the foot of the whiteboard
                            swimlane.deleted = true;
                            let nextIndex = this.swimlanes.length + newRows.length + 1000; //must leave room for new swimlanes being added
                            companionRow = new RowItem(nextIndex, RowItemTypeEnum.Swimlane, swimlane.swimlaneTitle, swimlane.swimlaneTitle, swimlane, 1);
                        }
        
                        let index = companionRow.index + 0.5; //swimlaneIndex + 0.5
                        let title = companionRow.title;
                        let c = this.getRowCellMultiplier(companionRow.swimlane.id, true);
                        let m = this.calcCellHeightMultiplier(c);
        
                        newRows.push(new RowItem(index, RowItemTypeEnum.BaselineSwimlane, title, title, companionRow.swimlane, m));
                    });
                }
                break;

            case DisplayMode.SwimlanesByLocation:




                break;
        }

        //ensure the rows are sorted by index
        this.rows = newRows.sort((a, b) => a.index - b.index);

        if (this.DEBUG) {
            var endTime = performance.now();
            console.log(`buildRows() took ${endTime - startTime}ms`);
        }
    }

    private buildGridCells(): void {
        if (this.DEBUG) { console.log('buildGridCells()'); }

        //NOTE: This must be run after both buildColumns() and buildRows() has been run!!

        let newCells: GridCellItem[] = [];

        if (!this.arrayHasItems(this.rows) || !this.arrayHasItems(this.columns)) {
            this.gridCells = newCells;
            return;
        }

        this.rows.forEach(row => {
            this.columns.forEach(column => {
                let cellClasses = `${column.multiplierClass} ${row.multiplierClass}`;
                if (column.isWeekend) { cellClasses += ' weekend'; }
                if (row.type == RowItemTypeEnum.BaselineSwimlane) { cellClasses += ' row-type-baseline'; }
                newCells.push(new GridCellItem(this.getCellPositionStyle(row, column), cellClasses, row, column));
            });
        });

        this.gridCells = newCells;
    }

    getColumnHeader(column: ColumnItem): string {
        let dt = column.date.plus({ days: column.index });
        return `${dt.toFormat('EEEE')}<br />${dt.toFormat('DD')}`;
    }

    getCellPositionStyle(row: RowItem, col: ColumnItem): string {
        let left = 0;
        for (let i = 0; i < col.index; i++) {
            let m = this.columns[i].multiplier;
            left += (m * this.cellSize);
        }

        let filteredRows = this.rows.filter(x => x.index < row.index);
        let top = 0;
        for (let row of filteredRows) {
            let m = row.multiplier;
            switch (row.type) {
                case RowItemTypeEnum.Swimlane: top += (m * this.cellSize); break;
                case RowItemTypeEnum.BaselineSwimlane: top += (m * this.baselineRowHeight); break;
            }
        }
        // for (let i = 0; i < row.index; i++) {
        //     let m = this.rows[i].multiplier;
        //     top += (m * this.cellSize);
        // }

        return `left: ${left}px;top: ${top}px;`;
    }

    getTaskStyle(task: PullPlanTaskDto | PullPlanTaskTrackedModel): string {
        let sap = this.getTaskTopLeft(task);
        return `position: absolute;left: ${sap.left}px;top: ${sap.top}px;`;
    }

    getTaskTopLeft(task: PullPlanTaskDto | PullPlanTaskTrackedModel): SizeAndPositionModel {
        let col = this.getColumnByDate(task.taskDate);
        let row = this.getRowBySwimlane(task.taskSwimlaneId, RowItemTypeEnum.Swimlane);

        if (this.isNullOrUndefined(col) || this.isNullOrUndefined(row)) {
            return SizeAndPositionModel.fromPosition(0, 0);
        }

        let ord = this.getTaskOrdinalPositionInCell(task, false);
        let sap = new SizeAndPositionModel();

        let left = 0;
        for (let i = 0; i < col.index; i++) {
            let m = this.columns[i].multiplier;
            left += (m * this.cellSize);
        }

        let mod = ord % col.multiplier;
        left += mod * this.cellSize;
        sap.left = left;

        let filteredRows = this.rows.filter(x => x.index < row.index);
        let top = 0;
        for (let row of filteredRows) {
            let m = row.multiplier;
            switch (row.type) {
                case RowItemTypeEnum.Swimlane: top += (m * this.cellSize); break;
                case RowItemTypeEnum.BaselineSwimlane: top += (m * this.baselineRowHeight); break;
            }
        }

        let mult = Math.floor(ord / col.multiplier);
        top += (mult * this.cellSize);
        sap.top = top;

        return sap;
    }

    getBaselineTaskStyle(task: PullPlanTaskDto): string {
        let sap = this.getBaselineTaskTopLeft(task);
        return `position: absolute;left: ${sap.left}px;top: ${sap.top}px;`;
    }

    getBaselineTaskTopLeft(task: PullPlanTaskDto): SizeAndPositionModel {
        let col = this.getColumnByDate(task.taskDate);
        let row = this.getRowBySwimlane(task.taskSwimlaneId, RowItemTypeEnum.BaselineSwimlane);

        if (this.isNullOrUndefined(col) || this.isNullOrUndefined(row)) {
            return SizeAndPositionModel.fromPosition(0, 0);
        }

        let ord = this.getTaskOrdinalPositionInCell(task, true);
        let sap = new SizeAndPositionModel();

        let left = 0;
        for (let i = 0; i < col.index; i++) {
            let m = this.columns[i].multiplier;
            left += (m * this.cellSize);
        }

        let mod = ord % col.multiplier;
        left += mod * this.cellSize;
        sap.left = left;

        let filteredRows = this.rows.filter(x => x.index < row.index);
        let top = 0;
        for (let row of filteredRows) {
            let m = row.multiplier;
            switch (row.type) {
                case RowItemTypeEnum.Swimlane: top += (m * this.cellSize); break;
                case RowItemTypeEnum.BaselineSwimlane: top += (m * this.baselineRowHeight); break;
            }
        }

        let mult = Math.floor(ord / col.multiplier);
        switch (row.type) {
            case RowItemTypeEnum.Swimlane: top += (mult * this.cellSize); break;
            case RowItemTypeEnum.BaselineSwimlane: top += (mult * this.baselineRowHeight); break;
        }

        sap.top = top;

        return sap;
    }

    //#region Activity Sizing & Positioning

    getActivityLeft(activity: ActivityDtoWithLayout): string {
        if (this.isNullOrUndefined(activity) || !this.arrayHasItems(this.columns)) { return ''; }

        let startColDate = this.columns[0].date;
        let finishCol = this.columns[this.columns.length - 1].date;

        //check if this activity starts *after* the finish date on the whiteboard grid
        if (this.isDateTimeLaterThan(activity.activityStart, finishCol.plus({ days: 1 }).minus({ seconds: 1 }))) { return ''; }

        let startDate = activity.activityStart;
        let startDateOnly = this.zeroTime(activity.activityStart);

        //if the activity starts *before* the start date on the whiteboard truncate the start to fit
        if (this.dateDiff(startDate, startColDate) > 0) {
            startDate = startColDate;
        }

        let startColumn = this.getColumnByDate(startDateOnly);
        if (this.isNullOrUndefined(startColumn)) { return ''; }

        //let leftMinutes = this.dateDiff(startColDate, startDate) / 1000 / 60; //milliseconds to minutes

        let dayMinutes = 24 * 60;

        let x = 0;
        for (let i = 0; i <= startColumn.index; i++) {
            let m = this.columns[i].multiplier;
            let colDate = this.columns[i].date;

            //is this the start date column or a column before the start date column?
            if (this.areDatesEqual(colDate, startDateOnly)) {
                x += Math.floor(this.ganttPixelsPerMinute * m * (this.dateDiff(colDate, startDate) / 1000 / 60));
            } else {
                x += Math.floor(this.ganttPixelsPerMinute * m * dayMinutes);
            }
        }

        return `${x}px`;
    }

    getActivityTop(index: number): string {
        return `${(index * this.ganttActivityHeight) + 3}px`;
    }

    getActivityWidth(activity: ActivityDtoWithLayout): string {
        if (this.isNullOrUndefined(activity) || !this.arrayHasItems(this.columns)) { return ''; }
        if (activity.activityType == ActivityTypeEnum.Milestone) { return ''; }

        let startColDate = this.columns[0].date;
        let finishColDate = this.columns[this.columns.length - 1].date.set({ hour: 23, minute: 59, second: 59 });
        let startDate = activity.activityStart;
        let startDateOnly = this.zeroTime(activity.activityStart);
        let finishDate = activity.activityFinish;

        //if the activity starts *after* the finish column, then return
        if (this.isDateTimeLaterThan(startDate, finishColDate)) {
            return '';
        }

        //if the activity finishes *before* the start column, then return
        if (this.isDateTimeEarlierThan(finishDate, startColDate)) {
            return '';
        }

        //if the activity starts *before* the start date on the whiteboard truncate the start to fit
        if (this.dateDiff(startDate, startColDate) > 0) {
            startDate = startColDate;
        }

        //if the activity finishes *after* the finish date on the whiteboard truncate the finish to fit
        if (this.dateDiff(finishColDate, finishDate) > 0) {
            finishDate = finishColDate;
        }

        let startColumn = this.getColumnByDate(startDate);
        if (this.isNullOrUndefined(startColDate)) { return ''; }

        let finishColumn = this.getColumnByDate(finishDate);
        if (this.isNullOrUndefined(finishColDate)) { return ''; }

        let dayMinutes = 24 * 60;

        let x = 0;
        for (let i = startColumn.index; i <= finishColumn.index; i++) {
            let m = this.columns[i].multiplier;
            let colDate = this.columns[i].date;

            if (this.areDatesEqual(colDate, startDateOnly)) {
                //start column
                let offsetMinutes = dayMinutes - this.dateDiff(colDate, startDate) / 1000 / 60; //milliseconds to minutes
                x += Math.floor(this.ganttPixelsPerMinute * m * offsetMinutes);
            } else if (this.areDatesEqual(colDate, finishDate)) {
                //finish column
                let offsetMinutes = this.dateDiff(colDate, finishDate) / 1000 / 60; //milliseconds to minutes
                x += Math.floor(this.ganttPixelsPerMinute * m * offsetMinutes);
            } else if (colDate.valueOf() > startDate.valueOf() && colDate.valueOf() < finishDate.valueOf()) {
                //intermediate column
                x += this.ganttPixelsPerMinute * m * dayMinutes;
            }
        }

        return `${x}px`;
    }

    //#endregion

    // private getSwimlaneByIndex(index: number): SwimlaneDto {
    //     return this.whiteboard.swimlanes.find(s => s.swimlaneIndex === index);
    // }

    private getSwimlaneById(id: number): SwimlaneDto {
        return this.swimlanes.find(s => s.id === id);
    }

    private getBaselineSwimlaneById(id: number): SwimlaneDto {
        //if(this.DEBUG) { console.log(`getBaselineSwimlaneById(id: ${id}) baselineSwimlanes:`, this.baselineSwimlanes); }
        return this.baselineSwimlanes.find(s => s.id === id);
    }

    // private getRowByIndex(index: number): RowItem {
    //     return this.rows.find(r => r.index === index);
    // }

    // private getColumnByIndex(index: number): ColumnItem {
    //     return this.columns.find(c => c.index === index);
    // }

    private getRowBySwimlane(id: number, type: RowItemTypeEnum): RowItem {
        return this.rows.find(r => r.swimlane.id === id && r.type === type);
    }

    private getColumnByDate(dt: DateTime): ColumnItem {
        return this.columns.find(c => this.areDatesEqual(c.date, dt)); //this comparison ignores timezone & time
    }
    //#endregion

    //#region SWIMLANE EDIT FUNCTIONS

    moveSwimlaneUp(row: RowItem): void {
        if (this.DEBUG) { console.log('moveSwimlaneUp() row:', row); }

        if (row.index == 0) { return; } //already at the top!

        let swimlane = this.getSwimlaneById(row.swimlane.id);
        let swimlaneIndex = swimlane.swimlaneIndex;

        //find the previous swimlane (index will be lower than the selected swimlane)
        let swimlanesAbove = this.swimlanes
            .filter(s => s.swimlaneIndex < row.index && !this.isDecimal(s.swimlaneIndex)) //exclude decimal indexes to avoid baseline swimlanes
            .sort((a, b) => b.swimlaneIndex - a.swimlaneIndex); //descending

        if (this.arrayHasItems(swimlanesAbove)) {
            let swapSwimlane = swimlanesAbove[0];
            let swapIndex = swapSwimlane.swimlaneIndex;
            let swimlaneIndex = swimlane.swimlaneIndex;

            swapSwimlane.swimlaneIndex = swimlane.swimlaneIndex;
            swimlane.swimlaneIndex = swapIndex;

            if (this.arrayHasItems(this.baselineSwimlanes)) {
                let baselineSwapSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == swapIndex + 0.5);
                let baselineSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == swimlaneIndex + 0.5);

                if (baselineSwapSwimlane != null) { baselineSwapSwimlane.swimlaneIndex = swimlaneIndex + 0.5; }
                if (baselineSwimlane != null) { baselineSwimlane.swimlaneIndex = swapIndex + 0.5; }
            }

            //this.updateAfterTaskChanges('moveSwimlaneUp()');

            this.SwimlaneMoved.emit(swimlane);
        }
    }

    moveSwimlaneDown(row: RowItem): void {
        if (this.DEBUG) { console.log('moveSwimlaneDown() row:', row); }

        //exclude decimal indexes to avoid baseline swimlanes
        let swimlanes = this.swimlanes.filter(s => !this.isDecimal(s.swimlaneIndex)).sort((a, b) => b.swimlaneIndex - a.swimlaneIndex); //descending
        let maxindex = swimlanes[0].swimlaneIndex;

        if (row.index == maxindex) { return; } //already at the bottom!

        let swimlane = this.getSwimlaneById(row.swimlane.id);

        //find the next swimlane (index will be higher than the selected swimlane)
        let swimlanesBelow = this.swimlanes
            .filter(s => s.swimlaneIndex > row.index && !this.isDecimal(s.swimlaneIndex)) //exclude decimal indexes to avoid baseline swimlanes
            .sort((a, b) => a.swimlaneIndex - b.swimlaneIndex); //ascending

        if (this.arrayHasItems(swimlanesBelow)) {
            let swapSwimlane = swimlanesBelow[0];
            let swapIndex = swapSwimlane.swimlaneIndex;
            let swimlaneIndex = swimlane.swimlaneIndex;

            swapSwimlane.swimlaneIndex = swimlane.swimlaneIndex;
            swimlane.swimlaneIndex = swapIndex;

            if (this.arrayHasItems(this.baselineSwimlanes)) {
                let baselineSwapSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == swapIndex + 0.5);
                let baselineSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == swimlaneIndex + 0.5);

                if (baselineSwapSwimlane != null) { baselineSwapSwimlane.swimlaneIndex = swimlaneIndex + 0.5; }
                if (baselineSwimlane != null) { baselineSwimlane.swimlaneIndex = swapIndex + 0.5; }
            }

            //this.updateAfterTaskChanges('moveSwimlaneDown()');

            this.SwimlaneMoved.emit(swimlane);
        }
    }

    insertSwimlaneAbove(row: RowItem): void {
        //raise event (so we can show the new Swimlane modal dialog)
        this.InsertSwimlaneAbove.emit(new InsertSwimlaneData(row, this.displayMode));
    }

    addSwimlaneAbove(row: RowItem, team: PullPlanTeamDto, location: BuildLocationDto): void {
        if (this.DEBUG) { console.log('addSwimlaneAbove() row:', row); }

        //insert a new swimlane above the row passed in

        let dto = new SwimlaneDto({
            id: this.nextNewSwimlaneId--,
            swimlaneIndex: row.index - 1, //this could go negative!
            swimlaneTitle: team.teamTitle,
            swimlaneWhiteboardId: this.whiteboardId,
            swimlaneType: DisplayMode.SwimlanesByProjectTeam,

            swimlaneTeamId: team?.id,
            swimlaneTeamTitle: team?.teamTitle,
            swimlaneTeamColour: team?.teamColour,
            swimlaneTeamTextColour: team?.teamTextColour,

            swimlaneLocationId: location?.id,
            swimlaneLocationTitle: location?.locationTitle,

            deleted: false,
            isBaseline: false
            // index: row.index - 1
        });

        //reindex swimlanes for the new entry
        let trackedIndexes: SwimlaneIndexTracking[] = [];
        let swimlanes = this.swimlanes.sort((a, b) => a.swimlaneIndex - b.swimlaneIndex);
        for (let i = 0; i < swimlanes.length; i++) {
            let swimlane = swimlanes[i];
            if (swimlane.swimlaneIndex <= dto.swimlaneIndex) {
                trackedIndexes.push(new SwimlaneIndexTracking(swimlane.id, swimlane.swimlaneIndex, swimlane.swimlaneIndex - 1));
                swimlane.swimlaneIndex--;
            }
        }

        if (this.arrayHasItems(trackedIndexes) && this.arrayHasItems(this.baselineSwimlanes)) {
            for (let i = 0; i < trackedIndexes.length; i++) {
                let baselineSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == trackedIndexes[i].oldIndex + 0.5);
                if (baselineSwimlane != null) {
                    baselineSwimlane.swimlaneIndex = trackedIndexes[i].newIndex + 0.5;
                }
            }
        }

        this.swimlanes.push(dto);

        this.reindexSwimlanes('addSwimlaneAbove()'); //reindex all swimlanes from zero

        //this.updateAfterTaskChanges('addSwimlaneAbove()');

        this.resyncScrollPosition(ScrollSyncEnum.Row);

        //this.newSwimlaneEdit(dto.swimlaneIndex);

        this.SwimlaneInsertedAbove.emit(new SwimlaneDto({
            //id: 0,
            id: dto.id,
            swimlaneIndex: row.index, //ensure the original row index is passed across
            swimlaneTitle: dto.swimlaneTitle,
            swimlaneWhiteboardId: dto.swimlaneWhiteboardId,
            swimlaneType: DisplayMode.SwimlanesByProjectTeam,

            swimlaneTeamId: team?.id,
            swimlaneTeamTitle: team?.teamTitle,
            swimlaneTeamColour: team?.teamColour,
            swimlaneTeamTextColour: team?.teamTextColour,

            swimlaneLocationId: location?.id,
            swimlaneLocationTitle: location?.locationTitle,

            deleted: false,
            isBaseline: false
            // index: row.index
        }));
    }

    insertSwimlaneBelow(row: RowItem): void {
        //raise event (so we can show the new Swimlane modal dialog)
        this.InsertSwimlaneBelow.emit(new InsertSwimlaneData(row, this.displayMode));
    }

    addSwimlaneBelow(row: RowItem, team: PullPlanTeamDto, location: BuildLocationDto): void {
        if (this.DEBUG) { console.log('addSwimlaneBelow() row:', row); }

        //insert a new swimlane below the row passed in

        let dto = new SwimlaneDto({
            id: this.nextNewSwimlaneId--,
            swimlaneIndex: row.index + 1,
            swimlaneTitle: team.teamTitle,
            swimlaneWhiteboardId: this.whiteboardId,
            swimlaneType: DisplayMode.SwimlanesByProjectTeam,

            swimlaneTeamId: team?.id,
            swimlaneTeamTitle: team?.teamTitle,
            swimlaneTeamColour: team?.teamColour,
            swimlaneTeamTextColour: team?.teamTextColour,

            swimlaneLocationId: location?.id,
            swimlaneLocationTitle: location?.locationTitle,
            
            deleted: false,
            isBaseline: false
            // index: row.index + 1
        });

        //reindex swimlanes for the new entry
        let trackedIndexes: SwimlaneIndexTracking[] = [];
        let swimlanes = this.swimlanes.sort((a, b) => a.swimlaneIndex - b.swimlaneIndex);
        for (let i = 0; i < swimlanes.length; i++) {
            let swimlane = swimlanes[i];
            if (swimlane.swimlaneIndex >= dto.swimlaneIndex) {
                trackedIndexes.push(new SwimlaneIndexTracking(swimlane.id, swimlane.swimlaneIndex, swimlane.swimlaneIndex - 1));
                swimlane.swimlaneIndex++;
            }
        }

        if (this.arrayHasItems(trackedIndexes) && this.arrayHasItems(this.baselineSwimlanes)) {
            for (let i = 0; i < trackedIndexes.length; i++) {
                let baselineSwimlane = this.baselineSwimlanes.find(s => s.swimlaneIndex == trackedIndexes[i].oldIndex + 0.5);
                if (baselineSwimlane != null) {
                    baselineSwimlane.swimlaneIndex = trackedIndexes[i].newIndex + 0.5;
                }
            }
        }

        this.swimlanes.push(dto);

        this.reindexSwimlanes('addSwimlaneBelow()'); //reindex all swimlanes from zero

        //this.updateAfterTaskChanges('addSwimlaneBelow()');

        this.resyncScrollPosition(ScrollSyncEnum.Row);

        //this.newSwimlaneEdit(dto.swimlaneIndex);

        this.SwimlaneInsertedBelow.emit(new SwimlaneDto({
            //id: 0,
            id: dto.id,
            swimlaneIndex: row.index, //ensure the original row index is passed across
            swimlaneTitle: dto.swimlaneTitle,
            swimlaneWhiteboardId: dto.swimlaneWhiteboardId,
            swimlaneType: DisplayMode.SwimlanesByProjectTeam,

            swimlaneTeamId: team?.id,
            swimlaneTeamTitle: team?.teamTitle,
            swimlaneTeamColour: team?.teamColour,
            swimlaneTeamTextColour: team?.teamTextColour,

            swimlaneLocationId: location?.id,
            swimlaneLocationTitle: location?.locationTitle,

            deleted: false,
            isBaseline: false
            // index: row.index
        }));
    }

    removeSwimlane(row: RowItem): void {
        if (this.DEBUG) { console.log('removeSwimlane() row:', row); }

        let swimlane = row.swimlane;

        //check first to make sure there are no locked tasks in this swimlane
        let lockedTasksCount = this.tasks.filter(t => t.taskSwimlaneId == swimlane.id && t.taskLocked).length;
        if (lockedTasksCount > 0) {
            this.message.warn(this.l('RemoveSwimlaneLockedTasksWarning', lockedTasksCount));
            return;
        }

        let taskCount = this.tasks.filter(task => task.taskSwimlaneId == swimlane.id).length;
        if (taskCount > 0) {
            this.message.confirm('', this.l('WhiteboardConfirmRemoveSwimlane'), (isConfirmed) => {
                if (isConfirmed) {
                    this.removeRow(row);
                }
            });
        } else {
            this.removeRow(row);
        }
    }

    private removeRow(row: RowItem): void {
        if (this.DEBUG) { console.log('removeRow() row:', row); }

        //remove the swimlane row
        for (let i = 0; i < this.swimlanes.length; i++) {
            let swimlane = this.swimlanes[i];
            if (swimlane.id == row.swimlane.id && swimlane.swimlaneIndex == row.index) {
                this.swimlanes.splice(i, 1);
                i = this.swimlanes.length; //exit for
            }
        }

        //detach the swimlaneid from affected tasks
        this.tasks.forEach(task => {
            if (task.taskSwimlaneId == row.swimlane.id) {
                task.taskSwimlaneId = undefined;
            }
        });

        this.reindexSwimlanes('removeRow()'); //reindex all swimlanes from zero
        this.updateAfterTaskChanges('removeRow()');
        this.resyncScrollPosition(ScrollSyncEnum.Grid);

        this.SwimlaneRemoved.emit(row.swimlane);
    }

    private reindexSwimlanes(source: string): void {
        if (this.DEBUG) { console.log(`reindexSwimlanes('${source}')`); }

        let indexTracking: SwimlaneIndexTracking[] = [];

        //get the swimlanes, sorted by their current swimlane index
        let swimlanes = this.swimlanes.sort((a, b) => a.swimlaneIndex - b.swimlaneIndex);

        //loop through and reindex them, starting from zero
        for (let i = 0; i < swimlanes.length; i++) {
            indexTracking.push(new SwimlaneIndexTracking(swimlanes[i].id, swimlanes[i].swimlaneIndex, i));
            swimlanes[i].swimlaneIndex = i;
        }

        this.swimlanes = swimlanes; //reassign the sorted swimlanes

        //get the next index to start from
        let nextIndex = this.swimlanes[this.swimlanes.length - 1].swimlaneIndex + 1;

        if (this.arrayHasItems(this.baselineSwimlanes)) {
            //get the baseline swimlanes, sorted by their current swimlane index (this will be the index they had)
            let baselineSwimlanes = this.baselineSwimlanes.sort((a, b) => a.swimlaneIndex - b.swimlaneIndex);
            for (let i = 0; i < this.baselineSwimlanes.length; i++) {
                let tracked = indexTracking.find(x => x.swimlaneId == baselineSwimlanes[i].id);
                if (!this.isNullOrUndefined(tracked)) {
                    baselineSwimlanes[i].swimlaneIndex = tracked.newIndex;
                } else {
                    if (this.DEBUG) { console.log(`no reindexing found for baseline swimlane (swimlaneId: ${baselineSwimlanes[i].id}, swimlaneIndex: ${baselineSwimlanes[i].swimlaneIndex}, swimlaneTitle: ${baselineSwimlanes[i].swimlaneTitle}!)`); }
                    baselineSwimlanes[i].swimlaneIndex = nextIndex + 0.5; //give new index
                    nextIndex++;
                }
            }
            this.baselineSwimlanes = baselineSwimlanes;
        }
    }
    //#endregion

    //#region DATE COLUMN EDIT FUNCTIONS

    addDateColumnToStart(column: ColumnItem): void {
        let start = this.zeroTime(this.whiteboardStartDate);
        this.whiteboardStartDate = start.plus({ days: -1 });

        this.buildGrid('addDateColumnToStart()');
        this.positionGanttActivities('addDateColumnToStart()');
        this.positionGanttActivityLinks('addDateColumnToStart()');
        this.positionTasks('addDateColumnToStart()');
        this.positionTaskLinks('addDateColumnToStart()');
        this.positionBaselineTasks('addDateColumnToStart()');
        this.setScrollLeftPosition(0);

        this.DateColumnAdded.emit(new WhiteboardDateRange(this.whiteboardStartDate, this.whiteboardEndDate));
    }

    public addDateColumnToEnd(column: ColumnItem, numberOfDays: number): void {
        let end = this.zeroTime(this.whiteboardEndDate);
        this.whiteboardEndDate = end.plus({ days: numberOfDays });

        this.buildGrid('addDateColumnToEnd()');
        this.positionGanttActivities('addDateColumnToEnd()');
        this.positionGanttActivityLinks('addDateColumnToEnd()');
        this.positionTasks('addDateColumnToEnd()');
        this.positionTaskLinks('addDateColumnToEnd()');
        this.positionBaselineTasks('addDateColumnToEnd()');
        this.setScrollLeftPosition((this.colHeadersElementRef.nativeElement as Element).scrollWidth);

        this.DateColumnAdded.emit(new WhiteboardDateRange(this.whiteboardStartDate, this.whiteboardEndDate));
    }

    removeDateColumn(column: ColumnItem): void {
        //check first to make sure there are no locked tasks in this date column
        let lockedTasksCount = this.tasks.filter(t => this.areDatesEqual(t.taskDate, column.date) && t.taskLocked).length;
        if (lockedTasksCount > 0) {
            this.message.warn(this.l('RemoveDateColumnLockedTasksWarning', lockedTasksCount));
            return;
        }

        let taskCount = this.tasks.filter(task => this.areDatesEqual(task.taskDate, column.date)).length;
        if (taskCount > 0) {
            this.message.confirm('', this.l('WhiteboardConfirmRemoveDateColumn'), (isConfirmed) => {
                if (isConfirmed) {
                    this.removeDateColumnFromWhiteboard(column);
                }
            });
        } else {
            this.removeDateColumnFromWhiteboard(column);
        }
    }

    private removeDateColumnFromWhiteboard(column: ColumnItem): void {
        let start = this.zeroTime(this.whiteboardStartDate);
        let end = this.zeroTime(this.whiteboardEndDate);

        if (+column.date === +start) {
            this.whiteboardStartDate = start.plus({ days: 1 });
        } else if (+column.date === +end) {
            this.whiteboardEndDate = end.plus({ days: -1 });
        }

        //detach the swimlaneid from affected tasks
        this.tasks.forEach(task => {
            if (this.areDatesEqual(task.taskDate, column.date)) {
                task.taskSwimlaneId = undefined;
            }
        });

        this.buildGrid('removeDateColumnFromWhiteboard()');
        this.positionGanttActivities('removeDateColumnFromWhiteboard()');
        this.positionGanttActivityLinks('removeDateColumnFromWhiteboard()');
        this.resyncScrollPosition(ScrollSyncEnum.Grid);
        this.updateAfterTaskChanges('removeDateColumnFromWhiteboard()');

        this.DateColumnRemoved.emit(new WhiteboardDateRange(this.whiteboardStartDate, this.whiteboardEndDate));
    }
    //#endregion

    addCellHoverClass(cell: Element): void {
        if (cell.className.indexOf(this.GridHoverClass) == -1) {
            cell.className += ` ${this.GridHoverClass}`;
        }
    }

    removeCellHoverClass(cell: Element): void {
        if (cell.className.indexOf(this.GridHoverClass) >= -1) {
            cell.className = cell.className.replace(this.GridHoverClass, '').trim();
        }
    }

    onTaskEdit(id: number): void {
        this.EditTask.emit(id);
    }

    onTaskDuplicate(id: number): void {
        this.DuplicateTask.emit(id);
    }

    onRemoveTask(id: number): void {
        let task = this.getTask(id);
        if (this.isNullOrUndefined(task)) { return; }

        //wipe the swimlaneId for this Task
        task.taskSwimlaneId = undefined;

        this.TaskRemoved.emit(task.toPullPlanTaskDto());
    }

    onDeleteTask(id: number): void {
        let task = this.getTask(id);
        if (this.isNullOrUndefined(task)) { return; }

        //pass this event straight through
        this.TaskDeleted.emit(id);
    }

    onCompleteTask(id: number): void {
        let task = this.getTask(id);
        if (this.isNullOrUndefined(task)) { return; }

        //pass this event straight through
        this.CompleteTask.emit(id);
    }

    taskSelectionChanged(data: ITaskSelectData): void {
        if (this.DEBUG) { console.log(`taskSelectionChanged(data.id: ${data.id}, data.selected: ${data.selected})`); }

        let task = this.tasks.find(t => t.id == data.id);
        task.selected = data.selected;

        this.checkTaskSelectionsInColumn(task.taskDate);
        this.checkTaskSelectionsInRow(task.taskSwimlaneId);
    }

    private getActivity(activityId: number): ActivityDtoWithLayout {
        let act = this.activities.find(a => a.id == activityId);
        if (this.isNullOrUndefined(act)) { return null; }
        return act;
    }

    private getTask(taskId: number): PullPlanTaskTrackedModel {
        let task = this.tasks.find(t => t.id == taskId);
        if (this.isNullOrUndefined(task)) { return null; }
        return task;
    }

    columnTitle(title: string): string {
        return title.replace('<br />', ', ');
    }

    toggleColumnMenu(ev: Event, column: ColumnItem): void {
        let menuitems = [
            {
                label: `<b>${this.columnTitle(column.title)}</b>`,
                escape: false,
                items: []
            }
        ];

        if (column.index == 0) {
            menuitems[0].items.push({
                label: this.l('WhiteboardAddDateColumnToStart'),
                icon: 'ds-icon-default-download rotate-90',
                command: () => this.addDateColumnToStart(column)
            });
        }

        if (column.index == this.columns.length - 1) {
            menuitems[0].items.push({
                label: this.l('WhiteboardAddDateColumnToEnd'),
                icon: 'ds-icon-default-upload rotate-90',
                command: () => this.addDateColumnToEnd(column, 1)
            });
        }

        menuitems[0].items.push({ separator: true });

        menuitems[0].items.push({
            label: this.l('WhiteboardRemoveDateColumn'),
            icon: 'ds-icon-default-trash',
            command: () => this.removeDateColumn(column)
        });

        this.columnMenuItems = menuitems;

        this.columnMenu.toggle(ev);
    }

    toggleGanttRowMenu(ev: Event, activity: ActivityDtoWithLayout): void {
        let menuitems = [
            {
                label: `<b>${activity.activityName}</b>`,
                escape: false,
                items: [
                    {
                        label: this.l('WhiteboardGanttCreateTasksFromActivity'),
                        icon: 'ds-icon-default-blueprint',
                        command: () => this.CreateTasksForActivity.emit(activity.id),
                        // disabled: this.areTasksLinkedToActivity(activity.id) // 12/06/2023 PMc #2575
                    },
                    //{ separator: true },
                ]
            }
        ];

        this.rowMenuItems = menuitems;

        this.rowMenu.toggle(ev);
    }

    toggleRowMenu(ev: Event, row: RowItem): void {
        let menuitems = [
            {
                label: `<b>${row.title}</b>`,
                escape: false,
                items: [
                    { label: this.l('WhiteboardInsertSwimlaneAbove'), icon: 'ds-icon-default-upload', command: () => this.insertSwimlaneAbove(row) },
                    { label: this.l('WhiteboardMoveSwimlaneUp'), icon: 'ds-icon-default-arrow-up', command: () => this.moveSwimlaneUp(row), disabled: this.isTopRow(row) },
                    { label: this.l('WhiteboardMoveSwimlaneDown'), icon: 'ds-icon-default-arrow-down', command: () => this.moveSwimlaneDown(row), disabled: this.isBottomRow(row) },
                    { label: this.l('WhiteboardInsertSwimlaneBelow'), icon: 'ds-icon-default-download', command: () => this.insertSwimlaneBelow(row) },
                    { separator: true },
                    { label: this.l('WhiteboardRemoveSwimlane'), icon: 'ds-icon-default-trash', command: () => this.removeSwimlane(row) },
                ]
            }
        ];

        this.rowMenuItems = menuitems;

        this.rowMenu.toggle(ev);
    }

    private isTopRow(row: RowItem): boolean {
        let sortedRows = this.rows.filter(row => row.type == RowItemTypeEnum.Swimlane).sort((a, b) => a.index - b.index);
        return row.index == sortedRows[0].index;
    }

    private isBottomRow(row: RowItem): boolean {
        let sortedRows = this.rows.filter(row => row.type == RowItemTypeEnum.Swimlane).sort((a, b) => a.index - b.index);
        return row.index == sortedRows[sortedRows.length - 1].index;
    }

    public setGanttRowState(visible: boolean): void {
        //this function is to ensure that the initial state of the gantt (if made visible)
        //correctly sizes both the gantt and the whiteboard correctly
        this.ganttRowVisible = visible;
        setTimeout(() => this.layoutGanttRow(), 50); //need to allow for sizing & positioning to have completed
    }

    toggleGanttRow(): void {
        this.ganttRowVisible = !this.ganttRowVisible;
        this.layoutGanttRow();
        this.GanttRowToggled.emit(this.ganttRowVisible);
    }

    layoutGanttRow(): void {
        if (this.ganttRowVisible) {
            this.positionGanttActivities('toggleGanttRow()');
            this.positionGanttActivityLinks('toggleGanttRow()');

            let expandedHeight = (this.ganttActivityHeight * this.activities.length) + 7;
            if (expandedHeight < this.ganttActivityHeight) { expandedHeight = this.ganttActivityHeight; }
            let componentHeight = this.whiteboardOuterContainerElementRef.nativeElement.clientHeight;
            let columnsRowHeight = this.colHeadersElementRef.nativeElement.clientHeight;
            let whiteboardHalfHeight = Math.floor((componentHeight - columnsRowHeight) / 2);
            if (expandedHeight > whiteboardHalfHeight) { expandedHeight = whiteboardHalfHeight; }
            this.ganttContentsMinHeightStyle = `${expandedHeight}px`;
        } else {
            this.ganttContentsMinHeightStyle = '';
        }
    }

    getActivityPosition(activity: ActivityDtoWithLayout): SizeAndPositionModel {
        let pos = new SizeAndPositionModel();
        pos.top = parseInt(activity.top, 10);
        pos.left = parseInt(activity.left, 10);
        pos.width = parseInt(activity.width, 10);
        pos.height = this.ganttActivityHeight;
        pos.xOffset = 0;
        pos.yOffset = 0;
        return pos;
    }

    getTaskPosition(task: PullPlanTaskTrackedModel | PullPlanTaskDto): SizeAndPositionModel {
        let sap = this.getTaskTopLeft(task);
        sap.width = this.taskSize;
        sap.height = this.taskSize;
        sap.xOffset = this.taskOffset;
        sap.yOffset = this.taskOffset;
        return sap;
    }

    private getDragNDropData(dataTransfer: DataTransfer): TaskDragData {
        let json = dataTransfer.getData('dragdata');

        if (this.isNullOrUndefinedOrEmptyString(json)) { return undefined; } //in case user drags anything else onto the grid!

        let dragData = (JSON.parse(json) as TaskDragData);

        //correct the date type (it doesn't deserialize to a luxon DateTime)
        dragData.task.taskDate = DateTime.fromISO(dragData.task.taskDate.toString());

        return dragData;
    }

    private getGridMousePosition(ev: DragEvent): SizeAndPositionModel {
        let gridbox = this.gridContentsElementRef.nativeElement;
        let gridpos = gridbox.getBoundingClientRect();
        let mouseX = ev.clientX + gridbox.scrollLeft - gridpos.x;
        let mouseY = ev.clientY + gridbox.scrollTop - gridpos.y;
        let mousePosition = SizeAndPositionModel.fromPosition(mouseX, mouseY);
        return mousePosition;
    }

    private resyncScrollPosition(source: ScrollSyncEnum): void {
        Promise.resolve(null).then(() => {
            let colHeaders = (this.colHeadersElementRef.nativeElement as Element);
            let ganttHeaders = (this.ganttRowHeadersElementRef.nativeElement as Element);
            let ganttRow = (this.ganttContentsElementRef.nativeElement as Element);
            let rowHeaders = (this.rowHeadersElementRef.nativeElement as Element);
            let grid = (this.gridContentsElementRef.nativeElement as Element);

            switch (source) {
                case ScrollSyncEnum.Grid:
                    rowHeaders.scrollTop = grid.scrollTop;
                    colHeaders.scrollLeft = grid.scrollLeft;
                    ganttRow.scrollLeft = grid.scrollLeft;
                    ganttHeaders.scrollTop = ganttRow.scrollTop;
                    break;

                case ScrollSyncEnum.Row:
                    grid.scrollTop = rowHeaders.scrollTop;
                    break;

                case ScrollSyncEnum.Column:
                    grid.scrollLeft = colHeaders.scrollLeft;
                    ganttRow.scrollLeft = grid.scrollLeft;
                    break;

                case ScrollSyncEnum.GanttRow:
                    ganttHeaders.scrollTop = ganttRow.scrollTop;
                    grid.scrollLeft = colHeaders.scrollLeft;
                    colHeaders.scrollLeft = grid.scrollLeft;
                    break;
            }
        });
    }

    private setScrollLeftPosition(newPos: number): void {
        this.suspendResyncScrollPosition = true;
        setTimeout(() => {
            let colHeaders = (this.colHeadersElementRef.nativeElement as Element);
            let ganttHeaders = (this.ganttRowHeadersElementRef.nativeElement as Element);
            let grid = (this.gridContentsElementRef.nativeElement as Element);
            colHeaders.scrollLeft = newPos;
            ganttHeaders.scrollLeft = newPos;
            grid.scrollLeft = newPos;
            this.suspendResyncScrollPosition = false;
        }, 1);
    }

    private updateAfterTaskChanges(source: string): void {
        if (this.DEBUG) { console.log(`updateAfterTaskChanges(source: ${source})`); }

        this.tasks.forEach((task) => {
            if (!this.isNullOrUndefinedOrNaN(task.taskSwimlaneId)) {
                let swimlane = this.getSwimlaneById(task.taskSwimlaneId);
                if (!this.isNullOrUndefined(swimlane)) {
                    task.swimlaneTeamTitle = swimlane.swimlaneTeamTitle;
                    task.swimlaneTeamColour = swimlane.swimlaneTeamColour;
                    task.swimlaneTeamTextColour = swimlane.swimlaneTeamTextColour;
                }
            }
        });

        this.buildGrid('updateAfterTaskChanges()');
        this.positionTasks('updateAfterTaskChanges()');
        this.positionTaskLinks('updateAfterTaskChanges()');
        this.positionGanttActivities('updateAfterTaskChanges()');
        this.positionGanttActivityLinks('updateAfterTaskChanges()');
        this.positionBaselineTasks('updateAfterTaskChanges()');
    }

    private areTasksLinkedToActivity(activityId: number): boolean {
        //problem here is that this whiteboard doesn't contain a list of unallocated tasks, some of which may be linked to this activityId!
        let check1 = this.tasks?.filter(t => t.taskPullPlanActivityLinkId == activityId);
        let check2 = this.unallocatedTasks?.filter(t => t.taskPullPlanActivityLinkId == activityId);
        return this.arrayHasItems(check1) || this.arrayHasItems(check2);
    }

    private calcCellWidthMultiplier(taskCount: number): number {
        //expand horizontally first, then vertically, then repeat same
        let val = Math.ceil(Math.sqrt(taskCount));
        if (val < 1) { val = 1; }

        return val;
    }

    private calcCellHeightMultiplier(taskCount: number): number {
        //expand horizontally first, then vertically, then repeat same
        let wm = this.calcCellWidthMultiplier(taskCount);
        let val = Math.ceil(Math.sqrt((taskCount + 1) - wm));
        if (val < 1) { val = 1; }
        return val;
    }

    private getColumnCellMultiplier(date: DateTime): number {
        let results = this.tasks.filter(t => this.areDatesEqual(t.taskDate, date) && !this.isNullOrUndefinedOrNaN(t.taskSwimlaneId));

        let max = 1; //default to 1 for empty columns

        results.forEach(task => {
            let countPerCell = results.filter(t => t.taskSwimlaneId == task.taskSwimlaneId).length;
            if (countPerCell > max) { max = countPerCell; }
        });

        return max;
    }

    private getBaselineColumnCellMultiplier(date: DateTime): number {
        let results = this.baselineTasks.filter(t => this.areDatesEqual(t.taskDate, date) && !this.isNullOrUndefinedOrNaN(t.taskSwimlaneId));

        let max = 1; //default to 1 for empty columns

        results.forEach(task => {
            let countPerCell = results.filter(t => t.taskSwimlaneId == task.taskSwimlaneId).length;
            if (countPerCell > max) { max = countPerCell; }
        });

        return max;
    }

    private getRowCellMultiplier(swimlaneId: number, isBaseline: boolean): number {
        let results: PullPlanTaskTrackedModel[] | PullPlanTaskDto[] = [];

        if (isBaseline) {
            results = this.baselineTasks.filter(t => t.taskSwimlaneId == swimlaneId);
        } else {
            results = this.tasks.filter(t => t.taskSwimlaneId == swimlaneId);
        }

        let max = 1; //default to 1 for empty rows
        results.forEach(task => {
            let countPerCell = results.filter(t => this.areDatesEqual(t.taskDate, task.taskDate) && t.taskSwimlaneId == task.taskSwimlaneId).length;
            if (countPerCell > max) { max = countPerCell; }
        });

        return max;
    }

    private getTasksInCell(swimlaneId: number, date: DateTime, isBaseline: boolean): any[] {
        let results: PullPlanTaskTrackedModel[] | PullPlanTaskDto[] = [];

        if (isBaseline) {
            results = this.baselineTasks;
        } else {
            results = this.tasks;
        }

        return results.filter(t => this.areDatesEqual(t.taskDate, date) && t.taskSwimlaneId == swimlaneId)
            .sort((a, b) => a.taskColumnCellIndex - b.taskColumnCellIndex || a.id - b.id); //sort by cell index, then by id
    }

    private getTaskOrdinalPositionInCell(task: PullPlanTaskDto | PullPlanTaskTrackedModel, isBaseline: boolean): number {
        //get task ids for this cell and order by task id to get the ordinal number
        if (this.isNullOrUndefined(task)) { return 0; }

        let results = this.getTasksInCell(task.taskSwimlaneId, task.taskDate, isBaseline);

        if (results.length <= 1) { return 0; }

        for (let i = 0; i < results.length; i++) {
            if (results[i].id == task.id) { return i; }
        }

        return 0;
    }

    toggleColumnSelection(column: ColumnItem): void {
        column.selected = !column.selected;
        this.tasks.forEach(task => {
            if (this.areDatesEqual(task.taskDate, column.date)) {
                task.selected = column.selected;
            }
        });
        this.rows.forEach(row => this.checkTaskSelectionsInRow(row.swimlane.id));
    }

    toggleRowSelection(row: RowItem): void {
        row.selected = !row.selected;
        this.tasks.forEach(task => {
            if (task.taskSwimlaneId == row.swimlane.id) {
                task.selected = row.selected;
            }
        });
        this.columns.forEach(col => this.checkTaskSelectionsInColumn(col.date));
    }

    private checkTaskSelectionsInColumn(taskDate: DateTime): void {
        //check if all tasks in this column are selected
        let columnTasks = this.tasks.filter(t => this.areDatesEqual(t.taskDate, taskDate));
        let allColTasksSelected = columnTasks.length > 0;
        columnTasks.forEach(ct => { if (!ct.selected) { allColTasksSelected = false; } });
        let column = this.columns.find(c => this.areDatesEqual(c.date, taskDate));
        column.selected = allColTasksSelected;
    }

    private checkTaskSelectionsInRow(taskSwimlaneId: number): void {
        //check if all tasks in this row are selected
        let rowTasks = this.tasks.filter(t => t.taskSwimlaneId == taskSwimlaneId);
        let allRowTasksSelected = rowTasks.length > 0;
        rowTasks.forEach(rt => { if (!rt.selected) { allRowTasksSelected = false; } });
        let row = this.rows.find(r => r.swimlane.id == taskSwimlaneId);
        row.selected = allRowTasksSelected;
    }

    clearSelections(): void {
        this.columns?.forEach(col => col.selected = false);
        this.rows?.forEach(row => row.selected = false);
    }

    // trackGanttRows: TrackByFunction<GanttRowItem> = (index, row) => row.activityId;
    trackGanttActivity: TrackByFunction<ActivityDtoWithLayout> = (index, activity) => activity.id;
    trackGanttActivityLink: TrackByFunction<ActivityLinkDto> = (index, link) => link.id;
    trackColumn: TrackByFunction<ColumnItem> = (index, column) => column.date.valueOf();
    trackRow: TrackByFunction<RowItem> = (index, row) => `${row.type}|${row.swimlane.id}|${row.index}`;
    trackCell: TrackByFunction<GridCellItem> = (index, cell) => parseFloat(`${cell.column.date.valueOf()}|${cell.row.type}|${cell.row.swimlane.id}`);
    trackTask: TrackByFunction<PullPlanTaskTrackedModel> = (index, task) => `TASK:${task.id}`;
    trackTaskLinks: TrackByFunction<PullPlanTaskPredecessorDto> = (index, link) => link.id;
    trackBaselineTask: TrackByFunction<PullPlanTaskDto> = (index, task) => `BASELINETASK:${task.id}`;

    private _scrollEventThrottlingTimer: any = null;

    scrollPositionsChanged(flag?: boolean): void {
        //throttle calls to this function, as scrolling produces a considerable number of calls in a short space of time!

        if (flag == null || !flag) {
            if (this._scrollEventThrottlingTimer != null) {
                clearTimeout(this._scrollEventThrottlingTimer);
            }
            this._scrollEventThrottlingTimer = setTimeout(() => this.scrollPositionsChanged(true), 50);
            return;
        }

        this._scrollEventThrottlingTimer = null;

        let scrollPositions = new WhiteboardScrollPositions(
            this.ganttContentsElementRef.nativeElement.scrollTop,
            this.gridContentsElementRef.nativeElement.scrollTop,
            this.gridContentsElementRef.nativeElement.scrollLeft
        );

        this.ScrollPositionsChanged.emit(scrollPositions);
    }

    public setScrollPositions(scrollPositions: WhiteboardScrollPositions): void {
        setTimeout(() => {
            if (scrollPositions.ganttVScroll != null) {
                this.ganttContentsElementRef.nativeElement.scrollTop = scrollPositions.ganttVScroll;
            }
            if (scrollPositions.vScroll != null) {
                this.gridContentsElementRef.nativeElement.scrollTop = scrollPositions.vScroll;
            }
            if (scrollPositions.hScroll != null) {
                this.gridContentsElementRef.nativeElement.scrollLeft = scrollPositions.hScroll;
            }
        }, 50);
    }

    renderWeekNumber(col: ColumnItem): string {
        return 'test';
    }

    //NEW METHODS TO REPLACE THE DATA BINDINGS

    public ChangeScale(newScale: number): void {
        this.scale = newScale;
        this.setScale('ChangeScale()');
    }

    loadData(whiteboard: WhiteboardDto, activityLinks: PullPlanActivityLinkDto[],
        tasks: PullPlanTaskTrackedModel[], unallocatedTasks: PullPlanTaskDto[],
        predecessorLinks: PullPlanTaskPredecessorDto[], baselineSwimlanes: SwimlaneDto[],
        baselineTasks: PullPlanTaskDto[]): void {

        this.whiteboardId = whiteboard.id;
        this.whiteboardStartDate = whiteboard.whiteboardStartDate;
        this.whiteboardEndDate = whiteboard.whiteboardEndDate;
        this.swimlanes = whiteboard.swimlanes;
        this.activityLinks = activityLinks;
        this.tasks = tasks;
        this.unallocatedTasks = unallocatedTasks;
        this.predecessorLinks = predecessorLinks;
        this.baselineSwimlanes = baselineSwimlanes;
        this.baselineTasks = baselineTasks;
        this.splitActivitiesFromPullPlan('loadData()');

        this.dataLoaded = true;

        this.buildGrid('loadData()');

        if (this.ganttRowVisible) {
            this.positionGanttActivities('loadData()');
            this.positionGanttActivityLinks('loadData()');
        }

        this.positionTasks('loadData()'); //this is handled in: ngAfterViewInit(pullPlanTaskComponents.changes.subscribe))
        this.positionTaskLinks('loadData()'); //this is handled in: ngAfterViewInit(taskLinkComponents.changes.subscribe))

        if (this.showBaselineContent) {
            this.positionBaselineTasks('loadData()');
        }

        this.resyncScrollPosition(ScrollSyncEnum.Grid);
    }


}