import { Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SizeAndPositionModel } from '@shared/SizeAndPositionModel';

@Component({
    selector: 'pullplan-task-link',
    templateUrl: './pullplan-task-link.component.html',
    styleUrls: ['./pullplan-task-link.component.scss']
})
export class PullplanTaskLinkComponent implements OnInit, OnChanges {

    @Input() id: number;
    @Input() taskId: number;
    @Input() parentTaskId: number;
    @Input() bringToForeground: boolean = false;
    @Input() highlight: boolean = false;
    @Input() highlightColour: string = 'orange';
    @Input() visible: boolean = false;

    linkLinePath: string = 'M0 0'; // 'M200 50 Q 63.41640786499875 10.83281572999748 100 100';
    childArrowPoints: string = '';
    svgWidth: string = '100%';
    svgHeight: string = '100%';
    lineColor: string = 'black';
    parentPointX: number = 0;
    parentPointY: number = 0;
    parentSPM: SizeAndPositionModel;
    childPointX: number = 0;
    childPointY: number = 0;
    childSPM: SizeAndPositionModel;

    constructor(
        private zone: NgZone,
    ) {

    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes) { return; }

        if (changes.highlight || changes.highlightColour) {
            this.lineColor = (this.highlight ? this.highlightColour : 'black');
        }
    }

    public drawLink(parentPosition: SizeAndPositionModel, childPosition: SizeAndPositionModel): void {
            this.setParentXY(parentPosition);
            this.setChildXY(childPosition);
            this.setSVG();
        this.visible = true;
    }

    private setParentXY(pos: SizeAndPositionModel): void {
        this.parentSPM = pos;
        this.parentPointX = pos.left + pos.width + pos.xOffset;
        this.parentPointY = pos.top + pos.yOffset + (pos.height / 2);
    }

    private setChildXY(pos: SizeAndPositionModel): void {
        this.childSPM = pos;
        this.childPointX = pos.left + pos.xOffset;
        this.childPointY = pos.top + pos.yOffset + (pos.height / 2);
    }

    private setSVG(): void {
        let ext = 100; //bezier curve extension
        if (this.parentPointY == this.childPointY && this.parentPointX < this.childPointX) {
            ext = 0;
        } else if (Math.abs(this.parentPointX - this.childPointX) <= ext) {
            ext = ext * 0.6;
        }

        this.linkLinePath = `M${this.parentPointX},${this.parentPointY} C${this.parentPointX + ext},${this.parentPointY} ${this.childPointX - ext},${this.childPointY} ${this.childPointX - 8},${this.childPointY}`;

        this.childArrowPoints = `${this.childPointX},${this.childPointY} ${this.childPointX - 10},${this.childPointY - 5} ${this.childPointX - 10},${this.childPointY + 5}`;

        //adapt container width & height
        // this.svgWidth = `${this.chooseGreater(this.parentSPM.left + this.parentSPM.width + (ext / 2), this.childSPM.left + this.childSPM.width + (ext / 2))}px`;
        // this.svgHeight = `${this.chooseGreater(this.parentSPM.top + this.parentSPM.height + (ext / 2), this.childSPM.top + this.childSPM.height + (ext / 2))}px`;
        this.svgWidth = `${this.chooseGreater(this.parentPointX + 10, this.childPointX + 10) + (ext / 2) + 1}px`;
        this.svgHeight = `${this.chooseGreater(this.parentPointY, this.childPointY) + 10}px`;
    }

    public moveParentPosition(pos: SizeAndPositionModel): void {
        this.zone.runOutsideAngular(() => {
            this.setParentXY(pos);
            this.setSVG();
        });
    }

    public moveChildPosition(pos: SizeAndPositionModel): void {
        this.zone.runOutsideAngular(() => {
            this.setChildXY(pos);
            this.setSVG();
        });
    }

    private chooseGreater(a: number, b: number): number {
        if (a > b) { return a; }
        return b;
    }

}
